import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import Axios from 'axios';
import { StickyContainer, Sticky } from 'react-sticky';

import Album from './Album'

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

class Inicio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lista_top: [],
      lista_fresh: [],
      lista_nuevas: [],
      lista_sec_1: [],
      lista_sec_2: [],
      lista_sec_3: [],
      lista_otras: [],

      //lista_misil: [],
      lista_videos: [],
      listas_anuncios: [],

      archivo: 0,
    };
  }

  componentWillMount () {
    window.H5_loading.show();
    //window.scrollTo(0, 0)

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    
    this.listaTodas();
    this.verArchivo();
  }
/*
  componentDidUpdate () {
    //this.listaTodas();
    //this.verArchivo();

  }*/

  componentDidMount () {
    document.title = "Dos... de Hidalgo"

    /*
    if((this.state.lista_top.length+this.state.lista_fresh.length)===0){
      setInterval(() => {
        if((this.state.lista_top.length+this.state.lista_fresh.length)===0){
          //alert('lol')
          window.location.href = window.rutas;
        }
      }, 5000);
    }
    */

    window.H5_loading.hide();
  }

  verArchivo() {
    window.H5_loading.show();
    var params = new URLSearchParams();
    params.append('cual', 'archivo');

    Axios.post(`${window.ws}wsPublica.php`, params)
      .then(response => {
        this.setState({
          archivo: response.data.archivo,
        })
      })
      .catch(error => {
        console.log(error)
      })
      .then(function() {
          window.H5_loading.hide();
      })
  }

  listaTodas() {
    window.H5_loading.show();
    var params = new URLSearchParams();
    //params.append('c_tipo', this.props.match.params.id);
    params.append('cual', 'index');

    Axios.post(`${window.ws}wsPublica.php`, params)
      .then(response => {    
        this.setState({
          lista_top: response.data.lista_top,
          lista_fresh: response.data.lista_fresh,
          lista_nuevas: response.data.lista_nuevas,

          lista_sec_1: response.data.lista_sec_1,
          lista_sec_2: response.data.lista_sec_2,
          lista_sec_3: response.data.lista_sec_3,
          lista_otras: response.data.lista_otras,

          listas_anuncios: response.data.listas_anuncios,
          lista_videos: response.data.lista_videos,
          //lista_misil: response.data.lista_misil,
        })
        console.log('consulta realizada')
      })
      .catch(error => {
        console.log(error)
      })
      .then(function() {
          window.H5_loading.hide();
      })
  }

  render () {
    return (
      <div>
        <div className="bg_gris">
          <div className="max_width padding_uno">
            <Album/>
          </div>
        </div>

        <div className="bg_blanco">
          {
            (this.state.lista_top+this.state.lista_fresh).length>0 ?
              <div className="max_width padding_width_3">
                  <div className="flex_top">
                    <div className="top_uno" data-aos="fade-right" data-aos-duration="1000">
                      {
                        this.state.lista_fresh.length>0 ?
                          <div>
                            <h51>Nuevas publicaciónes</h51>
                            <br/>
                            <h20>Revida el contenido más reciente</h20>
                            <br/><br/>
                            {
                              this.state.lista_fresh.map((item, i) => {
                                let link = item.not_titulo.replaceAll(/[&\/\\#,+()$~%.'":*?<>{}]/g, '') //eslint-disable-line
                                //link = link.replaceAll(/ /g, '')
                                link = link.substring(0, 500)
                                link = link+"&"+item.not_id

                                let miFecha = new Date(`${item.not_fecha.split(" ")[0]}T00:00`)
                                return(
                                  <div key={i} className="de_top_uno">
                                    <Link to={`../noticias/${link}`} className="texto_index size_index">
                                      {item.not_s>0 ? <h19>Exclusiva</h19> : null}
                                      {item.not_titulo}
                                    </Link>
                                    <br/>
                                    <Link to={{pathname:`secciones/${item.not_tipo}`, state: item.not_tipo}} className="tipo_index">{item.not_tipo}</Link> &nbsp;<h52>{`${window.meses[miFecha.getMonth()]} ${miFecha.getDate()}, ${miFecha.getFullYear()}`}</h52>
                                  </div>
                                )
                              })
                            }
                          </div>
                        :
                          null
                      }
                    </div>
                    <div className="top_dos">
                      {
                        this.state.lista_top.slice(0, 1).length>0 ?
                          this.state.lista_top.slice(0, 1).map((item, i) => {
                            let link = item.not_titulo.replaceAll(/[&\/\\#,+()$~%.'":*?<>{}]/g, '') //eslint-disable-line
                            //link = link.replaceAll(/ /g, '')
                            link = link.substring(0, 500)
                            link = link+"&"+item.not_id

                            let foto = ""
                            if(item.not_foto){
                              foto = `${window.ws}fotos/${item.not_id/182}.${item.not_foto}`
                            }else{
                              if(item.not_img){
                                foto = `${window.rutas}imagenes/noticias/${item.not_img}`
                              }else{
                                foto = "img/fondo.svg"
                              }
                            }

                            let texto = item.not_texto
                            if(item.not_texto.length>150){
                              texto = item.not_texto.substring(0,150)+"..."
                            }

                            return(
                              <Link to={`../noticias/${link}`} style={{backgroundImage: `url(${foto})`}} key={i} className="de_top_dos">
                                <div className="de_secciones">
                                  <Link to={{pathname:`secciones/${item.not_tipo}`, state: item.not_tipo}} className="top_dos_tipo">{item.not_tipo}</Link>
                                  <div id="cl"></div>
                                  <div className="grises con_top">{item.not_titulo}</div>
                                  <div dangerouslySetInnerHTML={{ __html: texto }} className="top_dos_texto"/>
                                </div>
                              </Link>
                            )
                          })
                        :
                          null
                      }
                    </div>
                    <div className="top_tres">
                      {
                        this.state.lista_top.slice(1, 3).length>0 ?
                          this.state.lista_top.slice(1, 3).map((item, i) => {
                            let link = item.not_titulo.replaceAll(/[&\/\\#,+()$~%.'":*?<>{}]/g, '') //eslint-disable-line
                            //link = link.replaceAll(/ /g, '')
                            link = link.substring(0, 500)
                            link = link+"&"+item.not_id

                            let foto = ""
                            if(item.not_foto){
                              foto = `${window.ws}fotos/${item.not_id/182}.${item.not_foto}`
                            }else{
                              if(item.not_img){
                                foto = `${window.rutas}imagenes/noticias/${item.not_img}`
                              }else{
                                foto = "img/fondo.svg"
                              }
                            }
                            return(
                              <div key={i}>
                                <Link to={`../noticias/${link}`} className="top_tres_img"><img src={foto} title={item.not_titulo} alt={item.not_titulo} className="img_index"/></Link>
                                <Link to={{pathname:`secciones/${item.not_tipo}`, state: item.not_tipo}} className="tipo_index">{item.not_tipo}</Link>
                                <br/>
                                <Link to={`../noticias/${link}`} className="texto_index size_index_1">{item.not_titulo}</Link>
                                {i===0 ? <div id="cl"><br/></div> : null}
                              </div>
                            )
                          })
                        :
                          null
                      }
                    </div>
                    <div className="top_cuatro" data-aos="fade-left" data-aos-duration="1000">
                      {
                        this.state.lista_top.slice(3, 7).length>0 ?
                          <div>
                            <h51>Exclusivas</h51>
                            <div className="top_borde">
                              {
                                this.state.lista_top.slice(3, 8).map((item, i) => {
                                  let link = item.not_titulo.replaceAll(/[&\/\\#,+()$~%.'":*?<>{}]/g, '') //eslint-disable-line
                                  //link = link.replaceAll(/ /g, '')
                                  link = link.substring(0, 500)
                                  link = link+"&"+item.not_id

                                  let miFecha = new Date(`${item.not_fecha.split(" ")[0]}T00:00`)
                                  return(
                                    <div key={i} className="de_top_cuatro">
                                      <Link to={{pathname:`secciones/${item.not_tipo}`, state: item.not_tipo}} className="tipo_index">{item.not_tipo}</Link>
                                      <br/>
                                      <Link to={`../noticias/${link}`} className="texto_index">{item.not_titulo}</Link>
                                      <br/>
                                      <h52>{`${window.meses[miFecha.getMonth()]} ${miFecha.getDate()}, ${miFecha.getFullYear()}`}</h52>
                                    </div>
                                  )
                                })
                              }
                            </div>
                          </div>
                        :
                          null
                      }
                    </div>
                  </div>  
              </div>
            :
              null
          }

          <div className="max_width padding_uno">
            <div className="flex_join">
              <div className="div_join">Suscribete a nuestras redes sociales</div>
              <div className="redes_join">
                {
                  window._my_redes.map((item, i) => 
                    <a href={item.link} target="_new" className="de_flex_contacto" key={i}><img src={item.imagen} alt={item.nombre} title={item.nombre}/></a>
                  )
                }
              </div>
            </div>
          </div>
        </div>
        {
          this.state.lista_nuevas.length > 0 ?
            <div className="bg_blanco">
              <div className="max_width">
                <div className="bg_gris padding_width_4 relative">
                  <titulo_nuevas>Nuevas</titulo_nuevas>
                  <div className="flex_secciones" data-aos="fade-down" data-aos-duration="1000">
                    {
                      this.state.lista_nuevas.slice(0, 4).map((item, i) => {
                        let link = item.not_titulo.replaceAll(/[&\/\\#,+()$~%.'":*?<>{}]/g, '') //eslint-disable-line
                        //link = link.replaceAll(/ /g, '')
                        link = link.substring(0, 500)
                        link = link+"&"+item.not_id

                        let foto = ""
                        if(item.not_foto){
                          foto = `${window.ws}fotos/${item.not_id/182}.${item.not_foto}`
                        }else{
                          if(item.not_img){
                            foto = `${window.rutas}imagenes/noticias/${item.not_img}`
                          }else{
                            foto = "img/fondo.svg"
                          }
                        }
                        return(
                          <Link to={`../noticias/${link}`} style={{backgroundImage: `url(${foto})`}} key={i}>
                            <div className="de_secciones">
                              <columna>{item.not_tipo}</columna>
                              <div id="cl"><br/></div>
                              {item.not_titulo}
                            </div>
                          </Link>
                        )
                      })
                    }
                  </div>
                  {
                    this.state.lista_nuevas.slice(4, 12).length > 0 ?
                      <div>
                        <div className="linea_gris"></div>
                        <div className="flex_nuevas">
                          <div className="nuevas">
                            {
                              this.state.lista_nuevas.slice(4, 6).length > 0 ?
                                <div>
                                  {
                                    this.state.lista_nuevas.slice(4, 6).map((item, i) => {
                                      let link = item.not_titulo.replaceAll(/[&\/\\#,+()$~%.'":*?<>{}]/g, '') //eslint-disable-line
                                      //link = link.replaceAll(/ /g, '')
                                      link = link.substring(0, 500)
                                      link = link+"&"+item.not_id

                                      let foto = ""
                                      if(item.not_foto){
                                        foto = `${window.ws}fotos/${item.not_id/182}.${item.not_foto}`
                                      }else{
                                        if(item.not_img){
                                          foto = `${window.rutas}imagenes/noticias/${item.not_img}`
                                        }else{
                                          foto = "img/fondo.svg"
                                        }
                                      }
                                      return(
                                        <div className="nuevas_ch_flex">
                                          <nuevas>
                                            <Link to={`../noticias/${link}`} className="texto_index">{item.not_s>0 ? <h19>Exclusiva</h19> : null}{item.not_titulo}</Link>
                                            <br/>
                                            <Link to={{pathname:`secciones/${item.not_tipo}`, state: item.not_tipo}} className="tipo_index">{item.not_tipo}</Link>
                                          </nuevas>
                                          <Link to={`../noticias/${link}`} className="nuevas_img"><img src={foto} title={item.not_titulo} alt={item.not_titulo} className="img_index"/></Link>
                                        </div>
                                      )
                                    })
                                  }
                                </div>
                              :
                                null
                            }
                          </div>
                          <div className="nuevas">
                            {
                              this.state.lista_nuevas.slice(6, 8).length > 0 ?
                                <div>
                                  {
                                    this.state.lista_nuevas.slice(6, 8).map((item, i) => {
                                      let link = item.not_titulo.replaceAll(/[&\/\\#,+()$~%.'":*?<>{}]/g, '') //eslint-disable-line
                                      //link = link.replaceAll(/ /g, '')
                                      link = link.substring(0, 500)
                                      link = link+"&"+item.not_id

                                      let foto = ""
                                      if(item.not_foto){
                                        foto = `${window.ws}fotos/${item.not_id/182}.${item.not_foto}`
                                      }else{
                                        if(item.not_img){
                                          foto = `${window.rutas}imagenes/noticias/${item.not_img}`
                                        }else{
                                          foto = "img/fondo.svg"
                                        }
                                      }
                                      return(
                                        <div className="nuevas_ch_flex">
                                          <nuevas>
                                            <Link to={`../noticias/${link}`} className="texto_index">{item.not_s>0 ? <h19>Exclusiva</h19> : null}{item.not_titulo}</Link>
                                            <br/>
                                            <Link to={{pathname:`secciones/${item.not_tipo}`, state: item.not_tipo}} className="tipo_index">{item.not_tipo}</Link>
                                          </nuevas>
                                          <Link to={`../noticias/${link}`} className="nuevas_img"><img src={foto} title={item.not_titulo} alt={item.not_titulo} className="img_index"/></Link>
                                        </div>
                                      )
                                    })
                                  }
                                </div>
                              :
                                null
                            }
                          </div>
                          <div className="nuevas">
                            {
                              this.state.lista_nuevas.slice(8, 10).length > 0 ?
                                <div>
                                  {
                                    this.state.lista_nuevas.slice(8, 10).map((item, i) => {
                                      let link = item.not_titulo.replaceAll(/[&\/\\#,+()$~%.'":*?<>{}]/g, '') //eslint-disable-line
                                      //link = link.replaceAll(/ /g, '')
                                      link = link.substring(0, 500)
                                      link = link+"&"+item.not_id

                                      let foto = ""
                                      if(item.not_foto){
                                        foto = `${window.ws}fotos/${item.not_id/182}.${item.not_foto}`
                                      }else{
                                        if(item.not_img){
                                          foto = `${window.rutas}imagenes/noticias/${item.not_img}`
                                        }else{
                                          foto = "img/fondo.svg"
                                        }
                                      }
                                      return(
                                        <div className="nuevas_ch_flex">
                                          <nuevas>
                                            <Link to={`../noticias/${link}`} className="texto_index">{item.not_s>0 ? <h19>Exclusiva</h19> : null}{item.not_titulo}</Link>
                                            <br/>
                                            <Link to={{pathname:`secciones/${item.not_tipo}`, state: item.not_tipo}} className="tipo_index">{item.not_tipo}</Link>
                                          </nuevas>
                                          <Link to={`../noticias/${link}`} className="nuevas_img"><img src={foto} title={item.not_titulo} alt={item.not_titulo} className="img_index"/></Link>
                                        </div>
                                      )
                                    })
                                  }
                                </div>
                              :
                                null
                            }
                          </div>
                          <div className="nuevas">
                            {
                              this.state.lista_nuevas.slice(10, 12).length > 0 ?
                                <div>
                                  {
                                    this.state.lista_nuevas.slice(10, 12).map((item, i) => {
                                      let link = item.not_titulo.replaceAll(/[&\/\\#,+()$~%.'":*?<>{}]/g, '') //eslint-disable-line
                                      //link = link.replaceAll(/ /g, '')
                                      link = link.substring(0, 500)
                                      link = link+"&"+item.not_id

                                      let foto = ""
                                      if(item.not_foto){
                                        foto = `${window.ws}fotos/${item.not_id/182}.${item.not_foto}`
                                      }else{
                                        if(item.not_img){
                                          foto = `${window.rutas}imagenes/noticias/${item.not_img}`
                                        }else{
                                          foto = "img/fondo.svg"
                                        }
                                      }
                                      return(
                                        <div className="nuevas_ch_flex">
                                          <nuevas>
                                            <Link to={`../noticias/${link}`} className="texto_index">{item.not_s>0 ? <h19>Exclusiva</h19> : null}{item.not_titulo}</Link>
                                            <br/>
                                            <Link to={{pathname:`secciones/${item.not_tipo}`, state: item.not_tipo}} className="tipo_index">{item.not_tipo}</Link>
                                          </nuevas>
                                          <Link to={`../noticias/${link}`} className="nuevas_img"><img src={foto} title={item.not_titulo} alt={item.not_titulo} className="img_index"/></Link>
                                        </div>
                                      )
                                    })
                                  }
                                </div>
                              :
                                null
                            }
                          </div>
                        </div>
                      </div>
                    :
                      null
                  }
                </div>
              </div>
            </div>
          :
           null
        }
        

        <div className="bg_blanco">
          <div className="max_width padding_uno">
            <StickyContainer className="flex_index_todas">
              <div className="index_todas_1">
                {
                  this.state.lista_videos.length > 0 ?
                    <div className="bg_blanco">
                      <div className="index_linea_todas">
                        <h50>Videos</h50>
                      </div>
                      <div className="flex_sec_1">
                        {
                          this.state.lista_videos.map((item, i) => {
                            var arrayVideo = item.not_video.split('/');
                            let miFecha = new Date(`${item.not_fecha.split(" ")[0]}T00:00`)
                            return(
                              <div className="top_flex_sec_1">
                                <iframe className="videos" src={`https://www.youtube.com/embed/${arrayVideo[3]}`} title={this.state.tu_titulo} allowfullscreen></iframe>
                                <div className="texto_index size_index">{item.not_titulo}</div>
                                <h52>{`${window.meses[miFecha.getMonth()]} ${miFecha.getDate()}, ${miFecha.getFullYear()}`}</h52>
                              </div>
                            )
                          })
                        }
                      </div>
                    </div>
                  :
                   null
                }
                <div id="cl"><br/><br/><br/></div>
                {
                  this.state.lista_sec_1.length>0 ?
                    <div data-aos="fade-down" data-aos-duration="1000">
                      {
                        window._my_secciones.filter(p => p.sec===1).map(item =>
                          <div className="index_linea_todas">
                            <h50>{item.titulo}</h50>
                            <Link to={{pathname:`secciones/${item.titulo}`, state: item.titulo}} className="view_all">Ver todas</Link>
                          </div>
                        )
                      }
                      <div className="flex_sec_1">
                        {
                          this.state.lista_sec_1.slice(0, 3).map((item, i) => {
                            let link = item.not_titulo.replaceAll(/[&\/\\#,+()$~%.'":*?<>{}]/g, '') //eslint-disable-line
                            //link = link.replaceAll(/ /g, '')
                            link = link.substring(0, 500)
                            link = link+"&"+item.not_id

                            let miFecha = new Date(`${item.not_fecha.split(" ")[0]}T00:00`)

                            let foto = ""
                            if(item.not_foto){
                              foto = `${window.ws}fotos/${item.not_id/182}.${item.not_foto}`
                            }else{
                              if(item.not_img){
                                foto = `${window.rutas}imagenes/noticias/${item.not_img}`
                              }else{
                                foto = "img/fondo.svg"
                              }
                            }
                            return(
                              <div className="top_flex_sec_1 top_flex_borde">
                                <Link to={`../noticias/${link}`}><img src={foto} title={item.not_titulo} alt={item.not_titulo} className="img_index"/></Link>
                                <Link to={`../noticias/${link}`} className="texto_index_sec">{item.not_s>0 ? <h19>Exclusiva</h19> : null}{item.not_titulo}</Link>
                                <br/>
                                <h52>{`${window.meses[miFecha.getMonth()]} ${miFecha.getDate()}, ${miFecha.getFullYear()}`}</h52>
                              </div>
                            )
                          })
                        }
                      </div>
                      {
                        this.state.lista_sec_1.slice(3, 6).length===3 ?
                          <div className="flex_sec_1">
                            {
                              this.state.lista_sec_1.slice(3, 6).map((item, i) => {
                                let link = item.not_titulo.replaceAll(/[&\/\\#,+()$~%.'":*?<>{}]/g, '') //eslint-disable-line
                                //link = link.replaceAll(/ /g, '')
                                link = link.substring(0, 500)
                                link = link+"&"+item.not_id

                                let miFecha = new Date(`${item.not_fecha.split(" ")[0]}T00:00`)
                                return(
                                  <div className="top_flex_sec_1">
                                    <div className="bot_flex_sec_1">
                                      <Link to={`../noticias/${link}`} className="texto_index">{item.not_s>0 ? <h19>Exclusiva</h19> : null}{item.not_titulo}</Link>
                                      <br/>
                                      <h52>{`${window.meses[miFecha.getMonth()]} ${miFecha.getDate()}, ${miFecha.getFullYear()}`}</h52>
                                    </div>
                                  </div>
                                )
                              })
                            }
                          </div>
                        :
                          null
                      }
                      {
                        this.state.lista_sec_1.slice(6, 9).length===3 ?
                          <div className="flex_sec_1">
                            {
                              this.state.lista_sec_1.slice(6, 9).map((item, i) => {
                                let link = item.not_titulo.replaceAll(/[&\/\\#,+()$~%.'":*?<>{}]/g, '') //eslint-disable-line
                                //link = link.replaceAll(/ /g, '')
                                link = link.substring(0, 500)
                                link = link+"&"+item.not_id

                                let miFecha = new Date(`${item.not_fecha.split(" ")[0]}T00:00`)
                                return(
                                  <div className="top_flex_sec_1">
                                    <div className="bot_flex_sec_1">
                                      <Link to={`../noticias/${link}`} className="texto_index">{item.not_s>0 ? <h19>Exclusiva</h19> : null}{item.not_titulo}</Link>
                                      <br/>
                                      <h52>{`${window.meses[miFecha.getMonth()]} ${miFecha.getDate()}, ${miFecha.getFullYear()}`}</h52>
                                    </div>
                                  </div>
                                )
                              })
                            }
                          </div>
                        :
                          null
                      }
                      {
                        this.state.lista_sec_1.slice(9, 12).length===3 ?
                          <div className="flex_sec_1">
                            {
                              this.state.lista_sec_1.slice(9, 12).map((item, i) => {
                                let link = item.not_titulo.replaceAll(/[&\/\\#,+()$~%.'":*?<>{}]/g, '') //eslint-disable-line
                                //link = link.replaceAll(/ /g, '')
                                link = link.substring(0, 500)
                                link = link+"&"+item.not_id

                                let miFecha = new Date(`${item.not_fecha.split(" ")[0]}T00:00`)
                                return(
                                  <div className="top_flex_sec_1">
                                    <div className="bot_flex_sec_1">
                                      <Link to={`../noticias/${link}`} className="texto_index">{item.not_s>0 ? <h19>Exclusiva</h19> : null}{item.not_titulo}</Link>
                                      <br/>
                                      <h52>{`${window.meses[miFecha.getMonth()]} ${miFecha.getDate()}, ${miFecha.getFullYear()}`}</h52>
                                    </div>
                                  </div>
                                )
                              })
                            }
                          </div>
                        :
                          null
                      }
                    </div>
                  :
                    null
                }
                <div id="cl"><br/><br/><br/></div>
                {
                  this.state.lista_sec_2.length>0 ?
                    <div data-aos="fade-down" data-aos-duration="1000">
                      {
                        window._my_secciones.filter(p => p.sec===2).map(item =>
                          <div className="index_linea_todas">
                            <h50>{item.titulo}</h50>
                            <Link to={{pathname:`secciones/${item.titulo}`, state: item.titulo}} className="view_all">Ver todas</Link>
                          </div>
                        )
                      }
                      <div className="flex_sec_1">
                        {
                          this.state.lista_sec_2.slice(0, 3).map((item, i) => {
                            let link = item.not_titulo.replaceAll(/[&\/\\#,+()$~%.'":*?<>{}]/g, '') //eslint-disable-line
                            //link = link.replaceAll(/ /g, '')
                            link = link.substring(0, 500)
                            link = link+"&"+item.not_id

                            let miFecha = new Date(`${item.not_fecha.split(" ")[0]}T00:00`)

                            let foto = ""
                            if(item.not_foto){
                              foto = `${window.ws}fotos/${item.not_id/182}.${item.not_foto}`
                            }else{
                              if(item.not_img){
                                foto = `${window.rutas}imagenes/noticias/${item.not_img}`
                              }else{
                                foto = "img/fondo.svg"
                              }
                            }
                            return(
                              <div className="top_flex_sec_1">
                                <Link to={`../noticias/${link}`}><img src={foto} title={item.not_titulo} alt={item.not_titulo} className="img_index"/></Link>
                                <Link to={`../noticias/${link}`} className="texto_index">{item.not_s>0 ? <h19>Exclusiva</h19> : null}{item.not_titulo}</Link>
                                <br/>
                                <h52>{`${window.meses[miFecha.getMonth()]} ${miFecha.getDate()}, ${miFecha.getFullYear()}`}</h52>
                              </div>
                            )
                          })
                        }
                      </div>
                      <div id="cl"><br/></div>
                      {
                        this.state.lista_sec_2.slice(3, 6).length===3 ?
                          <div className="flex_sec_1">
                            {
                              this.state.lista_sec_2.slice(3, 6).map((item, i) => {
                                let link = item.not_titulo.replaceAll(/[&\/\\#,+()$~%.'":*?<>{}]/g, '') //eslint-disable-line
                                //link = link.replaceAll(/ /g, '')
                                link = link.substring(0, 500)
                                link = link+"&"+item.not_id

                                let foto = ""
                                if(item.not_foto){
                                  foto = `${window.ws}fotos/${item.not_id/182}.${item.not_foto}`
                                }else{
                                  if(item.not_img){
                                    foto = `${window.rutas}imagenes/noticias/${item.not_img}`
                                  }else{
                                    foto = "img/fondo.svg"
                                  }
                                }

                                let miFecha = new Date(`${item.not_fecha.split(" ")[0]}T00:00`)
                                return(
                                  <div className="top_flex_sec_1">
                                    <Link to={`../noticias/${link}`}><img src={foto} title={item.not_titulo} alt={item.not_titulo} className="img_index"/></Link>
                                    <Link to={`../noticias/${link}`} className="texto_index">{item.not_s>0 ? <h19>Exclusiva</h19> : null}{item.not_titulo}</Link>
                                    <br/>
                                    <h52>{`${window.meses[miFecha.getMonth()]} ${miFecha.getDate()}, ${miFecha.getFullYear()}`}</h52>
                                  </div>
                                )
                              })
                            }
                          </div>
                        :
                          null
                      }
                    </div>
                  :
                    null
                }
                <div id="cl"><br/><br/><br/></div>
                {
                  this.state.lista_sec_3.length>0 ?
                    <div data-aos="fade-down" data-aos-duration="1000">
                      {
                        window._my_secciones.filter(p => p.sec===3).map(item =>
                          <div className="index_linea_todas">
                            <h50>{item.titulo}</h50>
                            <Link to={{pathname:`secciones/${item.titulo}`, state: item.titulo}} className="view_all">Ver todas</Link>
                          </div>
                        )
                      }
                      <div className="flex_sec_1">
                        {
                          this.state.lista_sec_3.slice(0, 3).map((item, i) => {
                            let link = item.not_titulo.replaceAll(/[&\/\\#,+()$~%.'":*?<>{}]/g, '') //eslint-disable-line
                            //link = link.replaceAll(/ /g, '')
                            link = link.substring(0, 500)
                            link = link+"&"+item.not_id

                            let miFecha = new Date(`${item.not_fecha.split(" ")[0]}T00:00`)

                            let foto = ""
                            if(item.not_foto){
                              foto = `${window.ws}fotos/${item.not_id/182}.${item.not_foto}`
                            }else{
                              if(item.not_img){
                                foto = `${window.rutas}imagenes/noticias/${item.not_img}`
                              }else{
                                foto = "img/fondo.svg"
                              }
                            }
                            return(
                              <div className="top_flex_sec_1 top_flex_borde">
                                <Link to={`../noticias/${link}`}><img src={foto} title={item.not_titulo} alt={item.not_titulo} className="img_index"/></Link>
                                <Link to={`../noticias/${link}`} className="texto_index_sec">{item.not_s>0 ? <h19>Exclusiva</h19> : null}{item.not_titulo}</Link>
                                <br/>
                                <h52>{`${window.meses[miFecha.getMonth()]} ${miFecha.getDate()}, ${miFecha.getFullYear()}`}</h52>
                              </div>
                            )
                          })
                        }
                      </div>
                      {
                        this.state.lista_sec_3.slice(3, 6).length===3 ?
                          <div className="flex_sec_1">
                            {
                              this.state.lista_sec_3.slice(3, 6).map((item, i) => {
                                let link = item.not_titulo.replaceAll(/[&\/\\#,+()$~%.'":*?<>{}]/g, '') //eslint-disable-line
                                //link = link.replaceAll(/ /g, '')
                                link = link.substring(0, 500)
                                link = link+"&"+item.not_id

                                let foto = ""
                                if(item.not_foto){
                                  foto = `${window.ws}fotos/${item.not_id/182}.${item.not_foto}`
                                }else{
                                  if(item.not_img){
                                    foto = `${window.rutas}imagenes/noticias/${item.not_img}`
                                  }else{
                                    foto = "img/fondo.svg"
                                  }
                                }
                                return(
                                  <div className="top_flex_sec_1">
                                    <div className="flex_sec_3">
                                      <Link to={`../noticias/${link}`} className="img_sec_3"><img src={foto} title={item.not_titulo} alt={item.not_titulo} className="img_index"/></Link>
                                      <div className="link_sec_3">
                                        <Link to={`../noticias/${link}`} className="texto_index">{item.not_s>0 ? <h19>Exclusiva</h19> : null}{item.not_titulo}</Link>
                                      </div>
                                    </div>
                                  </div>
                                )
                              })
                            }
                          </div>
                        :
                          null
                      }
                      {
                        this.state.lista_sec_3.slice(6, 9).length===3 ?
                          <div className="flex_sec_1">
                            {
                              this.state.lista_sec_3.slice(6, 9).map((item, i) => {
                                let link = item.not_titulo.replaceAll(/[&\/\\#,+()$~%.'":*?<>{}]/g, '') //eslint-disable-line
                                //link = link.replaceAll(/ /g, '')
                                link = link.substring(0, 500)
                                link = link+"&"+item.not_id

                                let foto = ""
                                if(item.not_foto){
                                  foto = `${window.ws}fotos/${item.not_id/182}.${item.not_foto}`
                                }else{
                                  if(item.not_img){
                                    foto = `${window.rutas}imagenes/noticias/${item.not_img}`
                                  }else{
                                    foto = "img/fondo.svg"
                                  }
                                }
                                return(
                                  <div className="top_flex_sec_1">
                                    <div className="flex_sec_3">
                                      <Link to={`../noticias/${link}`} className="img_sec_3"><img src={foto} title={item.not_titulo} alt={item.not_titulo} className="img_index"/></Link>
                                      <div className="link_sec_3">
                                        <Link to={`../noticias/${link}`} className="texto_index">{item.not_s>0 ? <h19>Exclusiva</h19> : null}{item.not_titulo}</Link>
                                      </div>
                                    </div>
                                  </div>
                                )
                              })
                            }
                          </div>
                        :
                          null
                      }
                    </div>
                  :
                    null
                }
              </div>
              <div className="index_todas_2">
                <Sticky>
                  {({
                    style,
                    isSticky,
                    wasSticky,
                    distanceFromTop,
                    distanceFromBottom,
                    calculatedHeight
                  }) => (
                    <header style={style} className="secciones_publicidad" data-aos="fade-left" data-aos-duration="1000">
                      {
                        this.state.listas_anuncios.length>0 ?
                          <div className="ver_publicidad">
                            {
                              this.state.listas_anuncios.map((item, i) => {
                                let foto = ""
                                if(item.not_foto){
                                  foto = `${window.ws}fotos/${item.not_id/182}.${item.not_foto}`
                                }else{
                                  if(item.not_img){
                                    foto = `${window.rutas}imagenes/noticias/${item.not_img}`
                                  }else{
                                    foto = "img/fondo.svg"
                                  }
                                }

                                return(
                                  <a href={foto} target="_new" key={i}><img src={foto} title={item.not_titulo} alt={item.not_titulo}/></a>
                                )
                              })
                            }
                          </div>
                        :
                          null
                      }
                    </header>
                  )}
                </Sticky>
              </div>
            </StickyContainer>
            <div className="ver_publicidad quita_pub_index">
              {
                this.state.listas_anuncios.map((item, i) => {
                  let foto = ""
                  if(item.not_foto){
                    foto = `${window.ws}fotos/${item.not_id/182}.${item.not_foto}`
                  }else{
                    if(item.not_img){
                      foto = `${window.rutas}imagenes/noticias/${item.not_img}`
                    }else{
                      foto = "img/fondo.svg"
                    }
                  }

                  return(
                    <a href={foto} target="_new" key={i}><img src={foto} title={item.not_titulo} alt={item.not_titulo}/></a>
                  )
                })
              }
            </div>
          </div>
        </div>

        <div className="bg_impresa">
          <div className="max_width padding_width_2">
            {
              this.state.archivo>0 ?
                <a className="edicion_impresa" href={`${window.ws}dosdehidalgo.pdf`} target="_new" data-aos="zoom-in" data-aos-duration="1000">
                  <center>
                    <img src="img/logo_blanco.svg" title="Dos... de Hidalgo" alt="Dos... de Hidalgo"/>
                    <div className="edicion_color"><h18>Versión impresa</h18><br/>Revisa el contenido completo</div>
                    <div id="cl"><br/><br/></div>
                    <bota className="descargar_impresa">Descargar aquí</bota>
                  </center>
                </a>
              :
                null
            }
          </div>
        </div>

        {/*
          this.state.lista_misil.length > 0 ?
            <div className="bg_blanco">
              <div className="max_width">
                <div className="bg_gris padding_width_4 relative">
                  <titulo_nuevas>Misiles</titulo_nuevas>
                  <div className="flex_cuatro">
                    {
                      this.state.lista_misil.map((item, i) => {
                        let foto = ""
                        if(item.not_foto){
                          foto = `${window.ws}fotos/${item.not_id/182}.${item.not_foto}`
                        }else{
                          if(item.not_img){
                            foto = `${window.rutas}imagenes/noticias/${item.not_img}`
                          }else{
                            foto = "img/fondo.svg"
                          }
                        }
                        return(
                          <div className="de_flex_cuatro">
                            <a href={foto} target="_new"><img src={foto} title={item.not_titulo} alt={item.not_titulo} className="img_index"/></a>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
            </div>
          :
           null
        */}

        {
          this.state.lista_otras.length>0 ?
            <div className="bg_blanco">
              <div className="max_width padding_width_2">
                <div className="index_linea_todas">
                  <h50>Otras</h50>
                </div>
                <div className="flex_cuatro" data-aos="fade-down" data-aos-duration="1000">
                  {
                    this.state.lista_otras.slice(0, 4).map((item, i) => {
                      let link = item.not_titulo.replaceAll(/[&\/\\#,+()$~%.'":*?<>{}]/g, '') //eslint-disable-line
                      //link = link.replaceAll(/ /g, '')
                      link = link.substring(0, 500)
                      link = link+"&"+item.not_id

                      let miFecha = new Date(`${item.not_fecha.split(" ")[0]}T00:00`)

                      let foto = ""
                      if(item.not_foto){
                        foto = `${window.ws}fotos/${item.not_id/182}.${item.not_foto}`
                      }else{
                        if(item.not_img){
                          foto = `${window.rutas}imagenes/noticias/${item.not_img}`
                        }else{
                          foto = "img/fondo.svg"
                        }
                      }
                      return(
                        <div className="de_flex_cuatro">
                          <Link to={`../noticias/${link}`}><img src={foto} title={item.not_titulo} alt={item.not_titulo} className="img_index"/></Link>
                          <Link to={`../noticias/${link}`} className="texto_index size_index">{item.not_s>0 ? <h19>Exclusiva</h19> : null}{item.not_titulo}</Link>
                          <br/>
                          <Link to={{pathname:`secciones/${item.not_tipo}`, state: item.not_tipo}} className="tipo_index">{item.not_tipo}</Link> &nbsp;<h52>{`${window.meses[miFecha.getMonth()]} ${miFecha.getDate()}, ${miFecha.getFullYear()}`}</h52>
                        </div>
                      )
                    })
                  }
                </div>
                <div id="cl"><br/></div>
                {
                  this.state.lista_otras.slice(4, 8).length>0 ?
                    <div className="flex_cuatro" data-aos="fade-down" data-aos-duration="1000">
                      {
                        this.state.lista_otras.slice(4, 8).map((item, i) => {
                          let link = item.not_titulo.replaceAll(/[&\/\\#,+()$~%.'":*?<>{}]/g, '') //eslint-disable-line
                          //link = link.replaceAll(/ /g, '')
                          link = link.substring(0, 500)
                          link = link+"&"+item.not_id

                          let miFecha = new Date(`${item.not_fecha.split(" ")[0]}T00:00`)

                          let foto = ""
                          if(item.not_foto){
                            foto = `${window.ws}fotos/${item.not_id/182}.${item.not_foto}`
                          }else{
                            if(item.not_img){
                              foto = `${window.rutas}imagenes/noticias/${item.not_img}`
                            }else{
                              foto = "img/fondo.svg"
                            }
                          }
                          return(
                            <div className="de_flex_cuatro">
                              <Link to={`../noticias/${link}`}><img src={foto} title={item.not_titulo} alt={item.not_titulo} className="img_index"/></Link>
                              <Link to={`../noticias/${link}`} className="texto_index size_index">{item.not_s>0 ? <h19>Exclusiva</h19> : null}{item.not_titulo}</Link>
                              <br/>
                              <Link to={{pathname:`secciones/${item.not_tipo}`, state: item.not_tipo}} className="tipo_index">{item.not_tipo}</Link> &nbsp;<h52>{`${window.meses[miFecha.getMonth()]} ${miFecha.getDate()}, ${miFecha.getFullYear()}`}</h52>
                            </div>
                          )
                        })
                      }
                    </div>
                  :
                    null
                }
              </div>
            </div>
          :
            null
        }


      </div>
    )
  }
}

export default Inicio;