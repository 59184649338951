import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { Element } from 'react-scroll';
import Axios from 'axios';

import { connect } from 'react-redux';
import verSecciones from './../redux/actionSecciones';
import verSeccionesTotal from './../redux/actionSeccionesTotal';
import verSeccionesDestacado from './../redux/actionSeccionesDestacado';
import verSeccionesCantidad from './../redux/actionSeccionesCantidad';

class Header extends Component {
  constructor() {
    super()
    this.state = {
      verMenu: 'ver_no',
      verCerrar: 'ver_no',
    }
    this.verMenu = this.verMenu.bind(this)
  }

  activa(e) {
    //alert(e)
    this.props.mostrarSeccionesCantidad(0)
    this.verMenu()
    this.listaTodas(e);
    this.listaDestacado(e);

    window.scrollTo(0, 0)
  }

  verMenu() {
    if(this.state.verMenu==='ver_no'){
      this.setState({verMenu: 'ver_si', verCerrar: 'ver_si_cerrar'})
    }else{
      this.setState({verMenu: 'ver_no', verCerrar: 'ver_no'})
    }
  }

  cerrarSesion (e) {
    if(localStorage.usuario){
      localStorage.removeItem('usuario')
      localStorage.removeItem('_ROL')
      localStorage.removeItem('_Nombre')
      localStorage.removeItem('_Activa')
    }
    window.location.href = window.rutas+"admin";
  }

  listaTodas(e) {
    window.H5_loading.show();
    var params = new URLSearchParams();
    params.append('c_tipo', e);
    params.append('inicial', 0);
    params.append('cual', 'secciones');

    Axios.post(`${window.ws}wsPublica.php`, params)
      .then(response => {
        if(response.data.elError===1){
          //this.setState({listas_all: []})
          this.props.mostrarSecciones([])
          console.log(response.data.mensaje)
        }else{
          //this.setState({listas_all: response.data.lista_array})
          this.props.mostrarSecciones(response.data.lista_array)
        }
      })
      .catch(error => {
        console.log(error)
      })
      .then(function() {
          window.H5_loading.hide();
      })
  }

  listaDestacado(e) {
    window.H5_loading.show();
    var params = new URLSearchParams();
    params.append('c_tipo', e);
    params.append('cual', 'secciones_destacado');

    Axios.post(`${window.ws}wsPublica.php`, params)
      .then(response => {      
        //this.setState({total_res: response.data.total_res})
        this.props.mostrarSeccionesTotal(response.data.total_res-response.data.lista_array.length)
        if(response.data.elError===1){
          //this.setState({listas_destacado: []})
          this.props.mostrarSeccionesDestacado([])
          console.log(response.data.mensaje)
        }else{
          //this.setState({listas_destacado: response.data.lista_array})
          this.props.mostrarSeccionesDestacado(response.data.lista_array)
        }
      })
      .catch(error => {
        console.log(error)
      })
      .then(function() {
          window.H5_loading.hide();
      })
  }

  render () {
    return (
      <div>
        <div className="boder_header">
          <Element name="inicio"></Element>
          <div className="max_width padding_header">
            <div className="header_flex">
              <div className="serach">
                {localStorage._ROL==='ADMIN' ? <img src={`${window.rutas}img/salir.svg`} title="Salir" alt="Salir" className="menu_salir grises" onClick={this.cerrarSesion}/> : null}
                <div className="flex_de_search grises puntero" onClick={this.verMenu}>
                  <div className="decir_menu">MENÚ</div>
                  <img src={`${window.rutas}img/menu.svg`} alt="Menú" title="Menú" className="menu_search"/>
                </div>
                {localStorage._ROL==='ADMIN' ? <Link to="../panel"><img src={`${window.rutas}img/panel.svg`} title="Panel" alt="Panel" className="menu_panel grises"/></Link> : null}
              </div>
              <Link to="../inicio"><img src={`${window.rutas}img/logo.svg`} alt="Dos... de Hidalgo" title="Dos... de Hidalgo" className="logo_header grises"/></Link>
              <div className="flex_redes">
                {
                  window._my_redes.map((item, i) => 
                    <a href={item.link} target="_new" key={i}><img src={window.rutas+item.imagen} alt={item.nombre} title={item.nombre}/></a>
                  )
                }
              </div>
            </div>
          </div>
        </div>

        <div className="menu_cerrar" id={this.state.verCerrar} onClick={this.verMenu}></div>
        <div className="menu_r" id={this.state.verMenu}>
          <div className="overflow">
            <center><Link to="../inicio" className="logo_men_head grises"><img src="../img/logo.svg" title="Dos... de Hidalgo" alt="Dos... de Hidalgo"/></Link></center>
            <div id="cl"></div>
            <ul>
              <li><Link to="../inicio" onClick={this.activa.bind(this)}>Inicio</Link></li>
              {localStorage._ROL==='ADMIN' ? <li><Link to="../panel" onClick={this.activa.bind(this)}>Panel</Link></li> : null}
              {
                window._my_secciones.filter(p => !p.no).map((item, i) =>
                  <li><Link to={`../secciones/${item.titulo}`} key={i} onClick={this.activa.bind(this, item.titulo)}>{item.titulo}</Link></li>
                )
              }
              <li><Link to="../contacto" onClick={this.activa.bind(this)}>Contacto</Link></li>
              {localStorage._ROL==='ADMIN' ? <li onClick={this.cerrarSesion}>Salir</li> : null}
            </ul>
            <div id="cl"><br/><br/></div>
          </div>
        </div>
      </div>
    )
  }
}

//export default Header
const mapStateToProps = (state) => {
  return {
    verSecciones: state.verSecciones,
    verSeccionesTotal: state.verSeccionesTotal,
    verSeccionesDestacado: state.verSeccionesDestacado,
    verSeccionesCantidad: state.verSeccionesCantidad
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    mostrarSecciones: (variable) => dispatch(verSecciones(variable)),
    mostrarSeccionesTotal: (variable) => dispatch(verSeccionesTotal(variable)),
    mostrarSeccionesDestacado: (variable) => dispatch(verSeccionesDestacado(variable)),
    mostrarSeccionesCantidad: (variable) => dispatch(verSeccionesCantidad(variable))
  }
}

const Liga = Header;
export default connect(mapStateToProps,mapDispatchToProps)(Liga);