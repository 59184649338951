import React, { Component } from 'react';
import Axios from 'axios'
import Swal from 'sweetalert2'
import { Element, Link as Ir } from 'react-scroll';
import Modal from 'react-bootstrap/Modal';

const alerta = (tipo,titulo) =>{
  Swal.fire({
  type: tipo,
  title: titulo
  });
}
/*
function UrlExists(url){ 
  var http = new XMLHttpRequest();
  http.open('HEAD', url, false);
  http.send();
  return http.status!=404;
}
*/



class Panel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      ver: 0,

      w_usuarios: [],

      c_usuario: '',
      c_tipo: '',
      c_titulo: '',
      c_s: '',
      c_fecha_1: '',
      c_fecha_2: '',
      c_recientes: 1,

      not_titulo: '',
      not_texto: '',
      not_fecha: `${window.hoy.getFullYear()}-${(window.hoy.getMonth())<10 ? '0' : ''}${window.hoy.getMonth()+1}-${(window.hoy.getDate())<10 ? '0' : ''}${window.hoy.getDate()}`,
      not_fecha_vence: '',
      not_tipo: '',
      not_foto: '',
      not_video: '',
      not_meta: '',
      not_s: '',
      not_usuario: '',
      documento: '',

      archivo: 0,

      inicial: 0,
      mostrar: 25,
      cantidad: 25,

      lista_todas: [],
    };
    this.botonEnviar = this.botonEnviar.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.mostrarChange = this.mostrarChange.bind(this);
    this.onSubir = this.onSubir.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentWillMount () {
    window.H5_loading.show();
    this.listaUsuarios();
    if(this.props.location.state){
      this.verNoticia(this.props.location.state)
    }
    window.scrollTo(0, 0)
  }
  
  componentDidMount () {
    document.title = "Panel"
    window.H5_loading.hide();
  }

  verArchivo() {
    window.H5_loading.show();
    var params = new URLSearchParams();
    params.append('cual', 'archivo');

    Axios.post(`${window.ws}wsPublica.php`, params)
      .then(response => {
        this.setState({
          archivo: response.data.archivo,
        })
      })
      .catch(error => {
        console.log(error)
      })
      .then(function() {
          window.H5_loading.hide();
      })
  }

  verNoticia(ver) {
    window.H5_loading.show();
    var params = new URLSearchParams();
    params.append('ver', ver);
    params.append('cual', 'noticias_editar');

    Axios.post(`${window.ws}wsPublica.php`, params)
      .then(response => {
        if(response.data.elError===1){
          //console.log(response.data.mensaje)
          alerta('warning',''+response.data.mensaje);
        }else{
          let contenidon = response.data.not_texto.replaceAll('<br />', '')
          this.setState({
            show: true,
            ver: response.data.not_id,
            not_titulo: response.data.not_titulo,
            not_texto: contenidon,
            not_fecha: response.data.not_fecha,
            not_fecha_vence: response.data.not_fecha_vence,
            not_tipo: response.data.not_tipo,
            not_foto: response.data.not_foto,
            not_video: response.data.not_video,
            not_meta: response.data.not_meta,
            not_s: response.data.not_s,
            not_usuario: response.data.not_usuario,
          })
        }
      })
      .catch(error => {
        console.log(error)
      })
      .then(function() {
          window.H5_loading.hide();
      })
  }

  handleChange(e) {
    const {name, value} = e.target;
    if(name==='not_s'){
      if(this.state.not_s===1){
        this.setState({not_s:0});
      }else{
        this.setState({not_s:1});  
      }
    }else if(name==='c_recientes'){
      if(this.state.c_recientes===1){
        this.setState({c_recientes:0});
      }else{
        this.setState({c_recientes:1});  
      }
    }else{
      this.setState({[name]:value})
    }
  }

  mostrarChange(e) {
    const {name, value} = e.target;
    this.setState({
      [name]:value,
      inicial: 0,
      mostrar: parseInt(value),
      cantidad: parseInt(value)
    })
  }

  verSiguiente(e){
    if(e===2){
      this.setState({
        mostrar: this.state.mostrar+this.state.cantidad,
        inicial: this.state.inicial+this.state.cantidad, 
      });
    }else{
      this.setState({
        mostrar: this.state.mostrar-this.state.cantidad,
        inicial: this.state.inicial-this.state.cantidad, 
      });
    }
  };

  botonEnviar (e) {
    e.preventDefault();  
    this.listaTodas();
  }

  handleClick(e) {
    const {ver, not_titulo, not_texto, not_fecha, not_fecha_vence, not_tipo, not_video, not_meta, not_s, documento} = this.state;
    if(!not_titulo || !not_fecha || !not_tipo || (not_tipo==='Video' && !not_video)){
      alerta('error','Un campo requerido está vacio');
    }else{
      window.H5_loading.show();
      var params = new URLSearchParams();
      params.append('_USUARIO', localStorage.usuario);
      params.append('_ROL', localStorage._ROL);
      params.append('_Activa', localStorage._Activa);
      params.append('id', ver);
      
      params.append('not_titulo', not_titulo);
      params.append('not_texto', not_texto);
      params.append('not_fecha', not_fecha);
      params.append('not_fecha_vence', not_fecha_vence);
      params.append('not_tipo', not_tipo);
      params.append('not_video', not_video);
      params.append('not_meta', not_meta);
      params.append('not_s', not_s);
      params.append('documento', documento);
      params.append('cual', 'registrar');

      Axios.post(`${window.ws}wsAdmin.php`, params)
        .then(response => {
          if(response.data.elError===1){
            alerta('success',''+response.data.mensaje);
            if(ver>0 && !this.props.location.state){
              this.listaTodas();
            }
            this.handleHide();
          }else{
            alerta('error',''+response.data.mensaje);
          }
        })
        .catch(function (error) {
          alerta('error',''+error);
          console.log(error)
        })
        .then(function(response) {
          window.H5_loading.hide();
        });
    }
  }

  onSubir(e) {
    if (!e.target.files[0]) {
      alerta('error','Un campo requerido está vacio');
    }else{
      let condicion = e.target.files[0].type === "image/png" || e.target.files[0].type === "image/jpeg" || e.target.files[0].type === "image/jpg"
      let leyenda = 'PNG o JPG'
      if(this.state.ver==='impresa'){
        condicion = e.target.files[0].type === "application/pdf"
        leyenda = 'PDF'
      }
      if(condicion){
        window.H5_loading.show();

        const config = {
          headers: {
              'content-type': 'multipart/form-data'
          }
        }

        const data = new FormData();
        data.append('file', e.target.files[0]);
        data.append('_USUARIO', localStorage.usuario);
        data.append('_ROL', localStorage._ROL);
        data.append('_Activa', localStorage._Activa);

        data.append('id', this.state.ver);

        Axios.post(window.ws+'wsDocumento.php', data, config)
        .then(response => {
          if(response.data.elError===1){
            alerta('success',response.data.mensaje)
            this.setState({
              documento: response.data.documento
            })
            if(this.state.ver==='impresa'){
              this.handleHide();
            }
          }else{
            alerta('error',response.data.mensaje)
          }
        })
        .catch(error => {
          //alerta('error',''+error);
          console.log(error)
        })
        .then(function() {
          window.H5_loading.hide();
        })
      }else{
        alerta('error','El archivo debe estar en formato '+leyenda);
      }
    }
  }

  handleShow(ver){
    window.H5_loading.show();
    this.setState({show: true})
    if(ver==='impresa'){
      this.verArchivo()
      this.setState({ver: ver})
    }else{
      if(ver!==''){
        this.state.lista_todas.filter(p => p.not_id === ver).map(item => { //eslint-disable-line
          let contenidon = item.not_texto.replaceAll('<br />', '')
          this.setState({
            ver: ver,
            not_titulo: item.not_titulo,
            not_texto: contenidon,
            not_fecha: item.not_fecha,
            not_fecha_vence: item.not_fecha_vence,
            not_tipo: item.not_tipo,
            not_foto: item.not_foto,
            not_video: item.not_video,
            not_meta: item.not_meta,
            not_s: item.not_s,
            not_usuario: item.not_usuario,
          })
        })
      }
    }
    window.H5_loading.hide();
  };

  handleHide(){
    if(this.props.location.state){
      let link = this.state.not_titulo.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '') //eslint-disable-line
      //link = link.replace(/ /g, '')
      link = link.substring(0, 500)
      link = link+"&"+this.props.location.state
      this.props.history.push('../noticias/'+link);
    }
    this.setState({
      show: false,
      ver: 0,
      not_titulo: '',
      not_texto: '',
      not_fecha: `${window.hoy.getFullYear()}-${(window.hoy.getMonth())<10 ? '0' : ''}${window.hoy.getMonth()+1}-${(window.hoy.getDate())<10 ? '0' : ''}${window.hoy.getDate()}`,
      not_fecha_vence: '',
      not_tipo: '',
      not_foto: '',
      not_video: '',
      not_meta: '',
      not_s: '',
      not_usuario: '',
      documento: '',
    })
  };

  delSo (e) {
    Swal.fire({
      title: '¿Deseas eliminar la publicación?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, eliminar!'
    }).then((result) => {
      if(result.value){
        window.H5_loading.show();

        var params = new URLSearchParams();
        params.append('_USUARIO', localStorage.usuario);
        params.append('_ROL', localStorage._ROL);
        params.append('_Activa', localStorage._Activa);
        params.append('id', e);
        params.append('cual', 'eliminar');

        Axios.post(`${window.ws}wsAdmin.php`, params)
          .then(response => {
            if(response.data.elError===1){
              alerta('success',''+response.data.mensaje);
              if(!this.props.location.state){
                this.listaTodas();
              }
              this.handleHide();
            }else{
              alerta('error',''+response.data.mensaje);
            }
          })
          .catch(error => {
            console.log(error)
            //alerta('error',''+error);
          })
          .then(function() {
              window.H5_loading.hide();
          })
      }
    })
  }

  listaTodas() {
    window.H5_loading.show();
    var params = new URLSearchParams();
    params.append('_USUARIO', localStorage.usuario);
    params.append('_ROL', localStorage._ROL);
    params.append('_Activa', localStorage._Activa);

    params.append('c_usuario', this.state.c_usuario);
    params.append('c_tipo', this.state.c_tipo);
    params.append('c_titulo', this.state.c_titulo);
    params.append('c_s', this.state.c_s);
    params.append('c_fecha_1', this.state.c_fecha_1);
    params.append('c_fecha_2', this.state.c_fecha_2);
    params.append('c_recientes', this.state.c_recientes);

    this.setState({
      inicial: 0,
      mostrar: 25,
      cantidad: 25,
    })

    Axios.post(`${window.ws}wsPublica.php`, params)
      .then(response => {
        if(response.data.elError===2){
          alerta('error',''+response.data.mensaje);
          this.setState({lista_todas: []})
        }else{
          this.setState({lista_todas: response.data.lista_array})
          if(response.data.elError===1){
            alerta('warning',''+response.data.mensaje);
          }
        }
      })
      .catch(error => {
        console.log(error)
        alerta('error',''+error);
      })
      .then(function() {
          window.H5_loading.hide();
      })
  }

  listaUsuarios() {
    window.H5_loading.show();
    Axios.get(window.ws+'listas.php')
      .then(response => {
        //window.alerta('success','LOLERO'+response.data);
        this.setState({w_usuarios: response.data})
      })
      .catch(error => {
        //window.alerta('error','LOLERO'+error);
        console.log(error)
      })
      .then(function() {
        window.H5_loading.hide();
      })
  }

  render () {
    /*
    let contenidon = this.state.not_texto.replaceAll('&amp;', '&')
    contenidon = contenidon.replaceAll('&ntilde;', 'ñ')
    contenidon = contenidon.replaceAll('&Ntilde;', 'Ñ')
    contenidon = contenidon.replaceAll('&aacute;', 'á')
    contenidon = contenidon.replaceAll('&eacute;', 'é')
    contenidon = contenidon.replaceAll('&iacute;', 'í')
    contenidon = contenidon.replaceAll('&oacute;', 'ó')
    contenidon = contenidon.replaceAll('&uacute;', 'ú')
    contenidon = contenidon.replaceAll('&Aacute;', 'Á')
    contenidon = contenidon.replaceAll('&Eacute;', 'É')
    contenidon = contenidon.replaceAll('&Iacute;', 'Í')
    contenidon = contenidon.replaceAll('&Oacute;', 'Ó')
    contenidon = contenidon.replaceAll('&Uacute;', 'Ú')
    contenidon = contenidon.replaceAll('<br />', '')
    contenidon = contenidon.replaceAll('&ldquo;', '“')
    contenidon = contenidon.replaceAll('&rdquo;', '”')
    contenidon = contenidon.replaceAll('&lsquo;', '‘')
    contenidon = contenidon.replaceAll('&rsquo;', '’')
    contenidon = contenidon.replaceAll('&iquest;', '¿')

    contenidon = contenidon.replaceAll('&lt;', '<')
    contenidon = contenidon.replaceAll('&gt;', '>')
    contenidon = contenidon.replaceAll('&quot;', '"')
    contenidon = contenidon.replaceAll('&apos;', "'")
    */
    return (
      <div>
        <div className="bg_gris">
          <div className="max_width padding_width">
            <div className="panel_izq">
              <h16>Bienvenido {localStorage._Nombre}</h16>
              <br/>
              <h15>{`${window.dia[window.date.getDay()]} ${window.date.getDate()} de ${window.meses[window.date.getMonth()]} de ${window.ano}`}</h15>
            </div>
            <br/>
            <h10>Panel</h10>
            <div className="linea"></div>
            <div id="cl"></div>
            <button className="ver_semaforo" onClick={this.handleShow.bind(this, 'impresa')}>Edición impresa</button>
            <div className="flex_nueva">
              <div className="nueva" onClick={this.handleShow.bind(this, '')}>+</div>
              <div className="l_nueva" onClick={this.handleShow.bind(this, '')}>Nueva</div>
            </div>
            <div id="cl"><br/></div>
            <form id="formulario2">
              <div className="pa_flex_form">
                <div className="de_flex_form">
                  Título:<br/>
                  <input type="text" placeholder="Título de la publicación" name="c_titulo" onChange={this.handleChange}/>
                  <div id="cl"></div>
                  Sección:<br/>
                  <select name="c_tipo" className="select" onChange={this.handleChange}>
                    <option value="">- Selecciona -</option>
                    {
                      window._my_secciones.map((item, i) =>
                        <option style={item.no===1 ? {backgroundColor: '#6EAB78'} : null} value={item.titulo} key={i}>{item.titulo}</option>
                      )
                    }
                  </select>
                  <div id="cl"></div>
                  {/*
                  Usuario:<br/>
                  <select name="c_usuario" className="select" onChange={this.handleChange}>
                    <option value="">- Selecciona -</option>
                    {
                      this.state.w_usuarios.map((item, i) =>
                        <option value={item[0]} key={i}>{item[1]}</option>
                      )
                    }
                  </select>
                  <div id="cl"></div>
                  */}
                </div>
                <div className="de_flex_form">
                  Periodo o Fecha:<br/>
                  <input type="date" name="c_fecha_1" placeholder="Fecha inicial (Día/Mes/Año)" onChange={this.handleChange}/>
                  <div id="cl"></div>
                  <input type="date" name="c_fecha_2" placeholder="Fecha final (Día/Mes/Año)" onChange={this.handleChange}/>
                  <div id="cl"></div>
                  <input type="checkbox" name="c_recientes" value={this.state.c_recientes} onChange={this.handleChange} checked={this.state.c_recientes===1 ? true : false}/>&nbsp;Recientes (<i>Muestra los primeros 50 resultados</i>)
                </div>
              </div>
              <div id="cl"><br/></div>
              <Ir to="recorrer" activeClass="active" spy={true} smooth={true} duration={1000}><button onClick={this.botonEnviar} className="button_con puntero">Consultar</button></Ir>
            </form>
            <div id="cl"></div>
            <Element name="recorrer"></Element>
            {
              this.state.lista_todas.length > 0 ?
                <div>
                  <div id="cl"><br/></div>
                  <div className="linea"></div>
                  <div id="cl"><br/></div>
                  {/*{this.state.inicial} de {this.state.mostrar} suma {this.state.cantidad}*/}
                  <div className="pa_flex_siguiente">
                    Resultados encontrados: {this.state.lista_todas.length}
                    {
                      this.state.lista_todas.length>25 ? 
                        <select name="mostrar" className="select" onChange={this.mostrarChange} style={{width:90,float:'right'}}>
                          <option value={25} selected={this.state.cantidad === 25 ? true : false}>25</option>
                          {this.state.lista_todas.length>=50 ? <option value={50} selected={this.state.cantidad === 50 ? true : false}>50</option> : null}
                          {this.state.lista_todas.length>=100 ? <option value={100} selected={this.state.cantidad === 100 ? true : false}>100</option> : null}
                          {this.state.lista_todas.length>=500 ? <option value={500} selected={this.state.cantidad === 500 ? true : false}>500</option> : null}
                          {this.state.lista_todas.length>=1000 ? <option value={1000} selected={this.state.cantidad === 1000 ? true : false}>1000</option> : null}
                          <option value={99999999999999999999} selected={this.state.cantidad === 99999999999999999999 ? true : false}>Todos</option>
                        </select>
                      :
                        null
                    }
                  </div>
                  <div className="tabla_responsiva">
                    <table className="carrillo" cellspacing="3">
                      <tr>
                        <th>#</th>
                        <th>Título</th>
                        <th>Sección</th>
                        <th>Foto</th>
                        <th>Fecha</th>
                        {/*<th>Usuario</th>*/}
                        <th></th>
                      </tr>
                      {
                        this.state.lista_todas.slice(this.state.inicial, this.state.mostrar).map((item, i) => {
                          var miFecha = new Date(`${item.not_fecha.split(" ")[0]}T00:00`)
                          return(
                            <tr key={i}>
                              <td>{item.contador}</td>
                              <td>{item.not_titulo}</td>
                              <td>{item.not_tipo}</td>
                              <td>
                                {
                                  item.not_foto ? 
                                    <a href={`${window.ws}fotos/${item.not_id/182}.${item.not_foto}`} target="_new" className="circulo_avisos">Ver</a>
                                  : item.not_img ? 
                                    <a href={`${window.rutas}imagenes/noticias/${item.not_img}`} target="_new" className="circulo_avisos">Ver</a>
                                  :
                                    "N/A"
                                }
                              </td>
                              <td>{miFecha.getDate()}/{window.meses[miFecha.getMonth()]}/{miFecha.getFullYear()}</td>
                              {/*<td>{this.state.w_usuarios.filter(p => p[0]===item.not_usuario).map(nombre => nombre[1])}</td>*/}
                              <td>
                                <img src="img/editar.png" alt="Editar" title="Editar" height="20px" onClick={this.handleShow.bind(this, item.not_id)} className="grises puntero"/>
                                <img src="img/eliminar.png" alt="Eliminar" title="Eliminar" height="20px" onClick={this.delSo.bind(this, item.not_id)} className="grises puntero"/>
                              </td>
                            </tr>
                          )
                        })
                      }
                    </table>
                    <div id="cl"><br/></div>
                  </div>
                  <div className="pa_flex_siguiente">
                    <div>{this.state.inicial === 0 ? null : <button onClick={this.verSiguiente.bind(this,1)} className="siguiente">Anterior</button>}</div>
                    <div>{this.state.mostrar >= this.state.lista_todas.length ? null : <button onClick={this.verSiguiente.bind(this,2)} className="siguiente">Siguiente</button>}</div>
                  </div>
                </div> 
              :
                null
            }
          </div>
        </div>
        <Modal
          show={this.state.show}
          onHide={this.handleHide.bind(this)}
          style={{paddingRight: 0}}
          className="modal_fer">

          <Modal.Body className="modal_cuadro_dos" data-aos="fade-down">
            <div className="jh-close" onClick={this.handleHide.bind(this)}>X</div>
              <form id="formulario3">
                <center><h17>{this.state.ver==='impresa' ? 'Edición  impresa' : this.state.ver===0 ? 'Registrar publicación' : 'Editar publicación'}</h17></center>
                {
                  this.state.ver==='impresa' ?
                    <div>
                      Archivo (Tamaño máximo 4M):<br/>
                      {
                        this.state.documento || this.state.archivo>0 ?
                          <a href={`${window.ws}dosdehidalgo.pdf`} target="_new">
                            <img src="img/documento.svg" title="Ver foto" alt="Ver foto" className="icono_archivo"/>
                          </a>
                        :
                          null
                      }
                      <input onChange={this.onSubir} id="file-upload" type="file" accept=".pdf"/>
                    </div>
                  : 
                    <div>
                      {this.state.ver>0 ? <div className="flo_der"><img src="img/eliminar.png" alt="Eliminar" title="Eliminar" height="20px" onClick={this.delSo.bind(this, this.state.ver)} className="grises puntero"/></div>: null}
                      <div id="cl"><br/></div>
                      *Título:<br/>
                      <input type="text" name="not_titulo" onChange={this.handleChange} value={this.state.not_titulo} required/>
                      <div id="cl"></div>
                      *Fecha:<br/>
                      <input type="date" name="not_fecha" onChange={this.handleChange} value={this.state.not_fecha} required/>
                      <div id="cl"></div>
                      *Sección:<br/>
                      <select name="not_tipo" className="select" onChange={this.handleChange} required>
                        <option value="" selected={this.state.not_tipo === "" ? true : false}>- Selecciona -</option>
                        {
                          window._my_secciones.map((item, i) =>
                            <option style={item.no===1 ? {backgroundColor: '#6EAB78'} : null} value={item.titulo} key={i} selected={this.state.not_tipo === item.titulo ? true : false}>{item.titulo}</option>
                          )
                        }
                      </select>
                      <div id="cl"></div>
                      {
                        this.state.not_tipo==="Misil" || this.state.not_tipo==="Anuncios" || this.state.not_tipo==="Video" ?
                          null
                        :
                          <div>
                            Descripción:<br/>
                            <textarea name="not_texto" id="area" onChange={this.handleChange} value={this.state.not_texto}/>
                            <div id="cl"></div>
                          </div>
                      }
                      {
                        this.state.not_tipo==="Misil" || this.state.not_tipo==="Anuncios" ?
                          null
                        :
                          <div>
                            {this.state.not_tipo==="Video" ? "*" : null}Video:<br/>
                            <input type="text" name="not_video" onChange={this.handleChange} placeholder="ej. https://youtu.be/" value={this.state.not_video}/>
                            <div id="cl"></div>
                          </div>
                      }
                      {/*
                      Meta:<br/>
                      <textarea name="tu_meta" id="area" onChange={this.handleChange} placeholder="Palabras clave(separar con ,):" value={this.state.tu_meta}/> 
                      <div id="cl"></div>
                      */}
                      <input type="checkbox" name="not_s" value={this.state.not_s} onChange={this.handleChange} checked={this.state.not_s===1 ? true : false}/>&nbsp;Destacado
                      <div id="cl"><br/></div>
                      {
                        this.state.not_tipo==="Video" ?
                          null
                        :
                          <div>
                            {this.state.not_tipo==="Misil" || this.state.not_tipo==="Anuncios" ? "*" : null}Foto (800px ancho x 550px alto o proporcional, tamaño máximo 600kb):<br/>
                            {
                              this.state.documento || this.state.not_foto ?
                                <a href={`${window.ws}fotos/${this.state.documento ? `_TEMPORAL.${this.state.documento}` : `${this.state.ver/182}.${this.state.not_foto}`}`} target="_new">
                                  <img src="img/documento.svg" title="Ver foto" alt="Ver foto" className="icono_archivo"/>
                                </a>
                              :
                                null
                            }
                            <input onChange={this.onSubir} id="file-upload" type="file" accept=".png, .jpg"/>
                            <div id="cl"><br/></div>
                          </div>
                      }
                      <h12>Campos obligatorios (*)</h12>
                      <div id="cl"><br/></div>
                      <center><div onClick={this.handleClick} className="button puntero">{this.state.ver===0 ? 'Registrar' : 'Editar'}</div></center>
                    </div>
                }
                <div id="cl"></div>
                <div className="espaciado_final"></div>
              </form>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}

export default Panel;
