import React, { Component } from 'react';
import Axios from 'axios'
import Swal from 'sweetalert2'

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

const alerta = (tipo,titulo) =>{
  Swal.fire({
  type: tipo,
  title: titulo
  });
}

class Contacto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nombre:'',
      correo:'',
      telefono:'',
      //asunto:'',
      mensaje:''
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentWillMount () {
    window.H5_loading.show();
    window.scrollTo(0, 0)
  }
  
  componentDidMount () {
    document.title = "Contacto"
    window.H5_loading.hide();
  }

  handleChange(e) {
    const {name, value} = e.target;
    this.setState({[name]:value})
  }

  handleClick(e) {
    e.preventDefault();  
    const {nombre, correo, mensaje, telefono/*, asunto*/} = this.state;
    if(nombre===''){
      alerta('error','Ingresa tu nombre completo');
    }else if(correo===''){
      alerta('error','Ingresa tu correo electrónico');
    }else if(window.validarMail(correo)===false){
      alerta('error','Ingrese un correo electrónico valido');
    }else if(mensaje===''){
      alerta('error','Escribe un mensaje');
    }else{
      window.H5_loading.show();
      var params = new URLSearchParams();
      params.append('nombre', nombre);
      params.append('correo', correo);
      params.append('mensaje', mensaje);
      params.append('telefono', telefono);
      //params.append('asunto', asunto);
      
      Axios.post(`${window.envia}`, params)
        .then(response => {
          if(response.data.elError===1){
            alerta('success',response.data.mensaje)
            this.setState({nombre:'',correo:'',mensaje:'',telefono:'',/*asunto:''*/});
          }else{
            alerta('error',response.data.mensaje)
          }
        })
        .catch(error => {
          //window.alerta('error','LOLERO'+error);
          console.log(error)
        })
        .then(function() {
          window.H5_loading.hide();
        })
    }
  }

  render () {
    return (
      <div>
        <div className="bg_gris">
          <div className="max_width padding_width">
            <center><h8>Contacto</h8></center>
            <div id="cl"><br/><br/></div>
            <div className="pa_flex_2">
              <div className="de_flex_2" data-aos="fade-left">
                <form id="formulario">
                  <h7>Deja tu mensaje:</h7>
                  <div id="cl"><br/></div>
                  <input type="text" name="nombre" onChange={this.handleChange} placeholder="Nombre:" value={this.state.nombre}/>
                  <input type="text" name="correo" onChange={this.handleChange} placeholder="Correo:" value={this.state.correo}/>
                  <input type="text" name="telefono" onChange={this.handleChange} placeholder="Teléfono:" value={this.state.telefono}/>
                  <textarea name="mensaje" id="area" onChange={this.handleChange} placeholder="Mensaje:" value={this.state.mensaje}/>                  
                  <div id="cl"><br/></div>
                  <center><div onClick={this.handleClick} className="button puntero">ENVIAR</div></center>
                </form>
              </div>
              <div className="de_flex_2 infcontacto" data-aos="fade-right">
                <h7>Información de contacto</h7>
                <ul className="list1">
                  <li className="telefono">
                    <a href={`tel:${window.telefono}`}>{window.telefono}</a>
                    <br/>
                    <a href={`tel:${window.telefono2}`}>{window.telefono2}</a>
                  </li>
                  <li className="correo">
                    <a href={`mailto:${window.correo}`}>{window.correo}</a>
                  </li>
                  {/*<li className="ubicacion"><a href={window.urlubi} target="_new">{window.ubicacion}</a></li>*/}
                </ul>
                <div id="cl"><br/></div>
                <h13>Síguenos en redes sociales:</h13>
                <div id="cl"><br/></div>
                <div className="redes_contacto">
                  {
                    window._my_redes.map((item, i) => 
                      <a href={item.link} target="_new" className="de_flex_contacto" key={i}><img src={item.imagen} alt={item.nombre} title={item.nombre}/></a>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Contacto;
