import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import Axios from 'axios';
import { Element, scroller } from 'react-scroll';
import { StickyContainer, Sticky } from 'react-sticky';

import { connect } from 'react-redux';
import verSecciones from './../redux/actionSecciones';
import verSeccionesTotal from './../redux/actionSeccionesTotal';
import verSeccionesDestacado from './../redux/actionSeccionesDestacado';
import verSeccionesCantidad from './../redux/actionSeccionesCantidad';

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

class Secciones extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //c_tipo: '',

      //inicial: 0,
      //mostrar: 15,

      //listas_all: [],
      //listas_destacado: [],
      //total_res: 0,
      listas_destacado: [],
      listas_anuncios: [],
    };
  }

  componentWillMount () {
    window.H5_loading.show();
    window.scrollTo(0, 0)

    let sitios = window._my_secciones.filter(p => p.titulo === this.props.match.params.id && !p.no);
    if(sitios.length===0){
      this.props.history.push('/');
    }else{
      if(this.props.verSecciones.length===0 || this.props.location.state){
        this.listaTodas(0);
        this.listaDestacado();
      }
      this.listaExcluciva();
      this.listaAnuncios();
      //this.listaTodas();
      //this.listaDestacado();
      //this.props.history.push('../admin');
    }
  }
/*
  componentWillReceiveProps() {
    window.H5_loading.show();

    let sitios = window._my_secciones.filter(p => p.titulo === this.props.match.params.id && !p.no);
    if(sitios.length===0){
      this.props.history.push('/');
    }

    window.H5_loading.hide();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    //document.title = this.props.match.params.id
  }*/

  componentWillUpdate () {
    window.H5_loading.show();
    document.title = this.props.match.params.id
  }
  
  componentDidMount () {
    document.title = this.props.match.params.id
    window.H5_loading.hide();
  }


  componentDidUpdate () {
    let sitios = window._my_secciones.filter(p => p.titulo === this.props.match.params.id && !p.no);
    if(sitios.length===0){
      this.props.history.push('/');
    }
    window.H5_loading.hide();
  }

  verSiguiente(e){
    scroller.scrollTo('seccion', {
      duration: 1500,
      smooth: true,
      spy:true,
    })
    let var_inicial = 0
    if(e===2){
      var_inicial = this.props.verSeccionesCantidad+window._my_cantidad_secciones
    }else{
      var_inicial = this.props.verSeccionesCantidad-window._my_cantidad_secciones
    }
    this.props.mostrarSeccionesCantidad(var_inicial)
    this.listaTodas(var_inicial);
  };

  listaTodas(cantidad) {
    window.H5_loading.show();
    var params = new URLSearchParams();
    params.append('c_tipo', this.props.match.params.id);
    params.append('inicial', cantidad);
    params.append('cual', 'secciones');

    Axios.post(`${window.ws}wsPublica.php`, params)
      .then(response => {
        if(response.data.elError===1){
          //this.setState({listas_all: []})
          this.props.mostrarSecciones([])
          console.log(response.data.mensaje)
        }else{
          //this.setState({listas_all: response.data.lista_array})
          this.props.mostrarSecciones(response.data.lista_array)
        }
      })
      .catch(error => {
        console.log(error)
      })
      .then(function() {
          window.H5_loading.hide();
      })
  }

  listaDestacado() {
    window.H5_loading.show();
    var params = new URLSearchParams();
    params.append('c_tipo', this.props.match.params.id);
    params.append('cual', 'secciones_destacado');

    Axios.post(`${window.ws}wsPublica.php`, params)
      .then(response => {      
        //this.setState({total_res: response.data.total_res})
        this.props.mostrarSeccionesTotal(response.data.total_res-response.data.lista_array.length)
        if(response.data.elError===1){
          //this.setState({listas_destacado: []})
          this.props.mostrarSeccionesDestacado([])
          console.log(response.data.mensaje)
        }else{
          //this.setState({listas_destacado: response.data.lista_array})
          this.props.mostrarSeccionesDestacado(response.data.lista_array)
        }
      })
      .catch(error => {
        console.log(error)
      })
      .then(function() {
          window.H5_loading.hide();
      })
  }

  listaExcluciva() {
    window.H5_loading.show();
    var params = new URLSearchParams();
    //params.append('c_tipo', this.props.match.params.id);
    params.append('cual', 'noticias_destacado');

    Axios.post(`${window.ws}wsPublica.php`, params)
      .then(response => {      
        if(response.data.elError===1){
          this.setState({listas_destacado: []})
          console.log(response.data.mensaje)
        }else{
          this.setState({listas_destacado: response.data.lista_array})
        }
      })
      .catch(error => {
        console.log(error)
      })
      .then(function() {
          window.H5_loading.hide();
      })
  }

  listaAnuncios() {
    window.H5_loading.show();
    var params = new URLSearchParams();
    //params.append('c_tipo', this.props.match.params.id);
    params.append('cual', 'anuncios');

    Axios.post(`${window.ws}wsPublica.php`, params)
      .then(response => {      
        if(response.data.elError===1){
          this.setState({listas_anuncios: []})
          console.log(response.data.mensaje)
        }else{
          this.setState({listas_anuncios: response.data.lista_array})
        }
      })
      .catch(error => {
        console.log(error)
      })
      .then(function() {
          window.H5_loading.hide();
      })
  }

  render () {
    return (
      <div>
        {
          this.props.verSeccionesDestacado.length > 0 ?
            <div className="bg_gris">
              <div className="max_width padding_width">
                <center><h8>{this.props.match.params.id}</h8></center>
                <div id="cl"><br/><br/></div>
                <div className="flex_secciones" data-aos="fade-down" data-aos-duration="1000">
                  {
                    this.props.verSeccionesDestacado.slice(0, 4).map((item, i) => {
                      
                      let link = item.not_titulo.replaceAll(/[&\/\\#,+()$~%.'":*?<>{}]/g, '') //eslint-disable-line
                      //link = link.replaceAll(/ /g, '')
                      link = link.substring(0, 500)
                      link = link+"&"+item.not_id

                      let foto = ""
                      if(item.not_foto){
                        foto = `${window.ws}fotos/${item.not_id/182}.${item.not_foto}`
                      }else{
                        if(item.not_img){
                          foto = `${window.rutas}imagenes/noticias/${item.not_img}`
                        }else{
                          foto = "../img/fondo.svg"
                        }
                      }

                      return(
                        <Link to={`../noticias/${link}`} style={{backgroundImage: `url(${foto})`}} key={i}>
                          <div className="de_secciones">
                            <columna>{item.not_tipo}</columna>
                            <div id="cl"><br/></div>
                            {item.not_titulo}
                          </div>
                        </Link>
                      )
                    })
                  }
                </div>
              </div>
            </div>
          :
           null
        }
        <Element name="seccion"></Element>
        <div className="bg_blanco">
          <div className="max_width padding_width">
            {
              this.props.verSecciones.length > 0 ?
                <div>
                  <StickyContainer className="flex_paginacion">
                    <div className="paginacion">
                      {
                        this.props.verSecciones.map((item, i) => {
                          let link = item.not_titulo.replaceAll(/[&\/\\#,+()$~%.'":*?<>{}]/g, '') //eslint-disable-line
                          //link = link.replaceAll(/ /g, '')
                          link = link.substring(0, 500)
                          link = link+"&"+item.not_id

                          let foto = ""
                          if(item.not_foto){
                            foto = `${window.ws}fotos/${item.not_id/182}.${item.not_foto}`
                          }else{
                            if(item.not_img){
                              foto = `${window.rutas}imagenes/noticias/${item.not_img}`
                            }else{
                              foto = "../img/fondo.svg"
                            }
                          }

                          let texto = item.not_texto
                          if(item.not_texto.length>150){
                            texto = item.not_texto.substring(0,150)+"..."
                          }

                          let miFecha = new Date(`${item.not_fecha.split(" ")[0]}T00:00`)

                          return(
                            <div className="not_sec" key={i}>
                              <Link to={`../noticias/${link}`} className="de_not_sec" data-aos="fade-right" data-aos-duration="500"><img src={foto} title={item.not_titulo} alt={item.not_titulo}/></Link>
                              <ordena data-aos="fade-left" data-aos-duration="500">
                                <Link to={`../noticias/${link}`} className="titulo_not_sec">{item.not_titulo}</Link>
                                <div className="fecha_not_sec"><b>{item.not_tipo}</b> | {`${window.meses[miFecha.getMonth()]} ${miFecha.getDate()}, ${miFecha.getFullYear()}`}</div>
                                <div dangerouslySetInnerHTML={{ __html: texto }} className="descripcion_not_sec"/>
                              </ordena>
                            </div>
                          )
                        })
                      }
                      {/*rex {this.props.verSeccionesTotal} redux {this.props.verSeccionesCantidad}*/}
                      <div className="pa_flex_siguiente">
                        <div className="fleex_siguiente">
                          <div>{this.props.verSeccionesCantidad === 0 ? null : <button onClick={this.verSiguiente.bind(this,1)} className="siguiente con_mar">Anterior</button>}</div>
                          <div>{(this.props.verSeccionesCantidad+window._my_cantidad_secciones) >= this.props.verSeccionesTotal ? null : <button onClick={this.verSiguiente.bind(this,2)} className="siguiente">Siguiente</button>}</div>  
                        </div>
                        <div className="num_pag">Página {(Math.ceil(this.props.verSeccionesCantidad/15)+1)} de {Math.ceil(this.props.verSeccionesTotal/15)}</div>
                      </div>
                    </div>
                    <div className="publicidad">
                      <Sticky>
                        {({
                          style,
                          isSticky,
                          wasSticky,
                          distanceFromTop,
                          distanceFromBottom,
                          calculatedHeight
                        }) => (
                          <header style={style} className="secciones_publicidad">
                            {
                              this.state.listas_destacado.length>0 ?
                                <div>
                                  {
                                    this.state.listas_anuncios.length>0 ?
                                      <div className="ver_publicidad">
                                        {
                                          this.state.listas_anuncios.map((item, i) => {
                                            let foto = ""
                                            if(item.not_foto){
                                              foto = `${window.ws}fotos/${item.not_id/182}.${item.not_foto}`
                                            }else{
                                              if(item.not_img){
                                                foto = `${window.rutas}imagenes/noticias/${item.not_img}`
                                              }else{
                                                foto = "../img/fondo.svg"
                                              }
                                            }

                                            return(
                                              <a href={foto} target="_new" key={i}><img src={foto} title={item.not_titulo} alt={item.not_titulo}/></a>
                                            )
                                          })
                                        }
                                      </div>
                                    :
                                      null
                                  }
                                  <h50>Exclusivas</h50>
                                  <div className="linea_exclusiva"></div>
                                  {
                                    this.state.listas_destacado.slice(0, 5).map((item, i) => {
                                      let link = item.not_titulo.replaceAll(/[&\/\\#,+()$~%.'":*?<>{}]/g, '') //eslint-disable-line
                                      //link = link.replaceAll(/ /g, '')
                                      link = link.substring(0, 500)
                                      link = link+"&"+item.not_id

                                      let foto = ""
                                      if(item.not_foto){
                                        foto = `${window.ws}fotos/${item.not_id/182}.${item.not_foto}`
                                      }else{
                                        if(item.not_img){
                                          foto = `${window.rutas}imagenes/noticias/${item.not_img}`
                                        }else{
                                          foto = "../img/fondo.svg"
                                        }
                                      }

                                      return(
                                        <Link to={`../noticias/${link}`} className="flex_exclusivas" key={i}>
                                          <img src={foto} title={item.not_titulo} alt={item.not_titulo}/>
                                          <div>{item.not_titulo}</div>
                                        </Link>
                                      )
                                    })
                                  }
                                </div>
                              :
                                null
                            }
                          </header>
                        )}
                      </Sticky>
                    </div>
                  </StickyContainer>
                  <div className="secciones_publicidad quita_sec_pub">
                    {
                      this.state.listas_destacado.length>0 ?
                        <div>
                          {
                            this.state.listas_anuncios.length>0 ?
                              <div className="ver_publicidad">
                                {
                                  this.state.listas_anuncios.map((item, i) => {
                                    let foto = ""
                                    if(item.not_foto){
                                      foto = `${window.ws}fotos/${item.not_id/182}.${item.not_foto}`
                                    }else{
                                      if(item.not_img){
                                        foto = `${window.rutas}imagenes/noticias/${item.not_img}`
                                      }else{
                                        foto = "../img/fondo.svg"
                                      }
                                    }

                                    return(
                                      <a href={foto} target="_new" key={i}><img src={foto} title={item.not_titulo} alt={item.not_titulo}/></a>
                                    )
                                  })
                                }
                              </div>
                            :
                              null
                          }
                          <h50>Exclusivas</h50>
                          <div className="linea_exclusiva"></div>
                          {
                            this.state.listas_destacado.slice(0, 5).map((item, i) => {
                              let link = item.not_titulo.replaceAll(/[&\/\\#,+()$~%.'":*?<>{}]/g, '') //eslint-disable-line
                              //link = link.replaceAll(/ /g, '')
                              link = link.substring(0, 500)
                              link = link+"&"+item.not_id

                              let foto = ""
                              if(item.not_foto){
                                foto = `${window.ws}fotos/${item.not_id/182}.${item.not_foto}`
                              }else{
                                if(item.not_img){
                                  foto = `${window.rutas}imagenes/noticias/${item.not_img}`
                                }else{
                                  foto = "../img/fondo.svg"
                                }
                              }

                              return(
                                <Link to={`../noticias/${link}`} className="flex_exclusivas" key={i}>
                                  <img src={foto} title={item.not_titulo} alt={item.not_titulo}/>
                                  <div>{item.not_titulo}</div>
                                </Link>
                              )
                            })
                          }
                        </div>
                      :
                        null
                    }
                  </div>
                </div>
              :
               <center>
                <h7>No se encontraron resultados</h7>
                <div id="cl"><br/></div>
                <Link to="../inicio" className="boton_contacto">Regresar</Link>
               </center>
            }
          </div>
        </div>
      </div>
    )
  }
}

//export default Secciones;
const mapStateToProps = (state) => {
  return {
    verSecciones: state.verSecciones,
    verSeccionesTotal: state.verSeccionesTotal,
    verSeccionesDestacado: state.verSeccionesDestacado,
    verSeccionesCantidad: state.verSeccionesCantidad
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    mostrarSecciones: (variable) => dispatch(verSecciones(variable)),
    mostrarSeccionesTotal: (variable) => dispatch(verSeccionesTotal(variable)),
    mostrarSeccionesDestacado: (variable) => dispatch(verSeccionesDestacado(variable)),
    mostrarSeccionesCantidad: (variable) => dispatch(verSeccionesCantidad(variable))
  }
}

const Liga = Secciones;
export default connect(mapStateToProps,mapDispatchToProps)(Liga);