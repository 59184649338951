//import React, { Component } from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import Axios from 'axios';
import { StickyContainer, Sticky } from 'react-sticky';
import { useParams } from 'react-router-dom';

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

function Noticias() {
  /*
  const [not_id, _not_id] = useState(0);
  const [not_titulo, _not_titulo] = useState('');
  const [not_texto, _not_texto] = useState('');
  const [not_fecha, _not_fecha] = useState('');
  const [not_fecha_vence, _not_fecha_vence] = useState('');
  const [not_tipo, _not_tipo] = useState('');
  const [not_img, _not_img] = useState('');
  const [not_foto, _not_foto] = useState('');
  const [not_video, _not_video] = useState('');
  const [not_meta, _not_meta] = useState('');
  const [not_s, _not_s] = useState('');
  const [not_usuario, _not_usuario] = useState('');
  const [listas_similar, _listas_similar] = useState([]);
  const [listas_destacado, _listas_destacado] = useState([]);
  */

  let parametro = useParams();

  const [noticia, setNoticia] = useState({
    not_id: 0,
    not_titulo: '',
    not_texto: '',
    not_fecha: '',
    //not_fecha_vence: '',
    not_tipo: '',
    not_img: '',
    not_foto: '',
    not_video: '',
    not_meta: '',
    not_s: '',
    not_usuario: '',

    listas_similar: [],
  });
  const [listas_destacado, set_listas_destacado] = useState([]);

  useEffect(() => {
    window.H5_loading.show();
    window.scrollTo(0, 0)
    //getCategories();
    verNoticia(parametro.id) 
    listaDestacado()

    window.H5_loading.hide();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const verNoticia = (ver) => {
    window.H5_loading.show();
    var params = new URLSearchParams();
    params.append('ver', ver);
    params.append('cual', 'noticias');

    Axios.post(`${window.ws}wsPublica.php`, params)
      .then(response => {
        if(response.data.elError===1){
          this.props.history.push('/');
          console.log(response.data.mensaje)
        }else{
          setNoticia({
            not_id: response.data.not_id,
            not_titulo: response.data.not_titulo,
            not_texto: response.data.not_texto,
            not_fecha: response.data.not_fecha,
            //not_fecha_vence: response.data.not_fecha_vence,
            not_tipo: response.data.not_tipo,
            not_img: response.data.not_img,
            not_foto: response.data.not_foto,
            not_video: response.data.not_video,
            not_meta: response.data.not_meta,
            not_s: response.data.not_s,
            not_usuario: response.data.not_usuario,

            listas_similar: response.data.lista_array,
          })
          document.title = response.data.not_titulo
        }
      })
      .catch(error => {
        console.log(error)
      })
      .then(function() {
          window.H5_loading.hide();
      })
  };

  const listaDestacado = () => {
    window.H5_loading.show();
    var params = new URLSearchParams();
    //params.append('c_tipo', this.props.match.params.id);
    params.append('cual', 'noticias_destacado');

    Axios.post(`${window.ws}wsPublica.php`, params)
      .then(response => {      
        if(response.data.elError===1){
          set_listas_destacado({listas_destacado: []})
          console.log(response.data.mensaje)
        }else{
          set_listas_destacado(response.data.lista_array)
        }
      })
      .catch(error => {
        console.log(error)
      })
      .then(function() {
          window.H5_loading.hide();
      })
  }

  const noticiaShow = (ver) => {
    verNoticia(ver)
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    this.props.history.push('../noticias/'+ver);
  }

  let arrayVideo
  if(noticia.not_video){
    arrayVideo = noticia.not_video.split('/');
  }
  

  //const { router, params, location, routes } = this.props
  //this.props.location.state recibir el state del Link
  let url = noticia.not_titulo.replaceAll(/[&\/\\#,+()$~%.'":*?<>{}]/g, '') //eslint-disable-line
  url = url.substring(0, 500)
  url = url.replaceAll(' ', '-')
  //link = link.replace(/ /g, '')
  //let url = parametro.id
  //url = url.replaceAll(' ', '+')


  let rutas = window.window.rutas.replaceAll(':', '%3A')
  rutas = rutas.replaceAll('/', '%2F')
  let face = rutas+"noticias%2F"+url+"%26"+noticia.not_id
  //face = "https://cambio21.com.mx/noticias/"+url+"&"+noticia.not_id
  //let twi = window.window.rutas+"noticias/"+url+"%26"+noticia.not_id

  //https://www.facebook.com/sharer.php?u=https%3A%2F%2Fdemo.tagdiv.com%2Fnewspaper_week_pro%2F2021%2F09%2F29%2Ftd-post-the-25-best-cities-you-can-find-in-italy-to-satisfy-the-love-for-pizza%2F
  //https://www.facebook.com/sharer.php?u=https://cambio21.com.mx/noticias/Garantizaran-la-inclusi%C3%B3n-de-Pueblos-Ind%C3%ADgenas-en-Hidalgo%26916370

  let twi = noticia.not_titulo.replaceAll(/ /g, '+')

  let foto = ""
  if(noticia.not_foto){
    foto = `${window.ws}fotos/${noticia.not_id/182}.${noticia.not_foto}`
  }else{
    if(noticia.not_img){
      foto = `${window.rutas}imagenes/noticias/${noticia.not_img}`
    }else{
      foto = "../img/fondo.svg"
    }
  }

  let miFecha = new Date(`${noticia.not_fecha.split(" ")[0]}T00:00`)

  return (
    <div>
      <div className="bg_blanco">
        <div className="max_width padding_width">
          <StickyContainer className="flex_noticias">
            <div className="noticias_ver">
              <div className="noticias_foto" data-aos="fade-right" data-aos-duration="1000">
                <img src={foto} title={noticia.not_titulo} alt={noticia.not_titulo}/>
                <div className="noticias_tipo">{noticia.not_tipo}</div>
              </div>

              <div className="noticia_titulo">{noticia.not_titulo}</div>
              <div className="noticia_fecha">
                {
                  localStorage._ROL==='ADMIN' ?
                    <div><Link to={{pathname:`../panel`, state: noticia.not_id}}><img src="../img/editar.png" alt="Editar" title="Editar" height="20px" className="grises puntero"/></Link></div>
                  :
                    null
                }
                {`${window.dia[miFecha.getDay()]} ${miFecha.getDate()} de ${window.meses[miFecha.getMonth()]} de ${miFecha.getFullYear()}`}
              </div>
              <div className="noticia_linea"></div>
              <br/>
              <div dangerouslySetInnerHTML={{ __html: noticia.not_texto }} className="noticia_texto"/>
              {
                noticia.not_video ?
                  <div>
                    <div id="cl"><br/></div>
                    <iframe className="videos_noticias" src={`https://www.youtube.com/embed/${arrayVideo[3]}`} title={noticia.not_titulo} allowfullscreen></iframe>
                    <div id="cl"></div>
                  </div>
                :
                  null
              }
              <br/>
              <div className="noticia_linea"></div>
              <div className="leyenda_compa" data-aos="fade-left" data-aos-duration="1000">
                Compartir publicación
                <div className="compartir start">
                  <a href={`https://www.facebook.com/sharer.php?u=${face}`} target="_new"><img src="../img/r_face-01.svg" title="Facebook" alt="Facebook"/></a>
                  <a href={`https://twitter.com/intent/tweet?text=${twi}&url=${face}`} target="_new"><img src="../img/r_twi-01.svg" title="Twitter" alt="Twitter"/></a>
                  <a href={`https://api.whatsapp.com/send?text=${twi}%20%0A%0A%20${face}`} target="_new"><img src="../img/r_whats-01.svg" title="WhastApp" alt="WhastApp"/></a>
                </div>
              </div>
            </div>
            <div className="noticias_seguir">
              <Sticky>
                {({
                  style,
                  isSticky,
                  wasSticky,
                  distanceFromTop,
                  distanceFromBottom,
                  calculatedHeight
                }) => (
                  <header style={style} className="noticias_publicidad" data-aos="fade-left" data-aos-duration="1000">
                    {
                      listas_destacado.length>0 ?
                        <div>
                          <h50>Exclusivas</h50>
                          <div className="linea_exclusiva"></div>
                          {
                            listas_destacado.filter(p => p.not_id!==noticia.not_id).slice(0, 5).map((item, i) => {
                              let link = item.not_titulo.replaceAll(/[&\/\\#,+()$~%.'":*?<>{}]/g, '') //eslint-disable-line
                              //link = link.replace(/ /g, '')
                              link = link.substring(0, 500)
                              link = link+"&"+item.not_id

                              let foto = ""
                              if(item.not_foto){
                                foto = `${window.ws}fotos/${item.not_id/182}.${item.not_foto}`
                              }else{
                                if(item.not_img){
                                  foto = `${window.rutas}imagenes/noticias/${item.not_img}`
                                }else{
                                  foto = "../img/fondo.svg"
                                }
                              }

                              return(
                                <div className="flex_exclusivas" onClick={noticiaShow.bind(this, link)} key={i}>
                                  <img src={foto} title={item.not_titulo} alt={item.not_titulo}/>
                                  <div>{item.not_titulo}</div>
                                </div>
                              )
                            })
                          }
                        </div>
                      :
                        null
                    }
                  </header>
                )}
              </Sticky>
            </div>
          </StickyContainer>

          {
            listas_destacado.length>0 ?
              <div className="noticias_publicidad quita_publicidad" data-aos="fade-right" data-aos-duration="500">
                <h50>Exclusivas</h50>
                <div className="linea_exclusiva"></div>
                {
                  listas_destacado.filter(p => p.not_id!==noticia.not_id).slice(0, 5).map((item, i) => {
                    let link = item.not_titulo.replaceAll(/[&\/\\#,+()$~%.'":*?<>{}]/g, '') //eslint-disable-line
                    //link = link.replace(/ /g, '')
                    link = link.substring(0, 500)
                    link = link+"&"+item.not_id

                    let foto = ""
                    if(item.not_foto){
                      foto = `${window.ws}fotos/${item.not_id/182}.${item.not_foto}`
                    }else{
                      if(item.not_img){
                        foto = `${window.rutas}imagenes/noticias/${item.not_img}`
                      }else{
                        foto = "../img/fondo.svg"
                      }
                    }

                    return(
                      <div className="flex_exclusivas" onClick={noticiaShow.bind(this, link)} key={i}>
                        <img src={foto} title={item.not_titulo} alt={item.not_titulo}/>
                        <div>{item.not_titulo}</div>
                      </div>
                    )
                  })
                }
              </div>
            :
              null
          }         

        </div>
      </div>
      
      {
        noticia.listas_similar.length>0 ? 
          <div className="bg_gris">
            <div className="max_width padding_width">
              <center><h14>Te puede interesar</h14></center>
              <div id="cl"><br/><br/></div>
              <div className="flex_similar" data-aos="fade-down" data-aos-duration="1000">
                {
                  noticia.listas_similar.slice(0, 4).map((item, i) => {
                    let link = item.not_titulo.replaceAll(/[&\/\\#,+()$~%.'":*?<>{}]/g, '') //eslint-disable-line
                    //link = link.replace(/ /g, '')
                    link = link.substring(0, 500)
                    link = link+"&"+item.not_id

                    let foto = ""
                    if(item.not_foto){
                      foto = `${window.ws}fotos/${item.not_id/182}.${item.not_foto}`
                    }else{
                      if(item.not_img){
                        foto = `${window.rutas}imagenes/noticias/${item.not_img}`
                      }else{
                        foto = "../img/fondo.svg"
                      }
                    }

                    let miFecha = new Date(`${item.not_fecha.split(" ")[0]}T00:00`)

                    return(
                      <similar key={i}>
                        <img src={foto} title={item.not_titulo} alt={item.not_titulo} onClick={noticiaShow.bind(this, link)}/>
                        <div onClick={noticiaShow.bind(this, link)} className="similar_titulo">{item.not_titulo}</div>
                        <div className="similar_fecha">{item.not_tipo} | {`${window.meses[miFecha.getMonth()]} ${miFecha.getDate()}, ${miFecha.getFullYear()}`}</div>
                      </similar>
                    )
                  })
                }
              </div>
            </div>
          </div>
        :
          null
      }
    </div>
  )
}
/*
class Noticias extends Component {
  constructor(props) {
    super(props);
    this.state = {
      not_id: 0,
      not_titulo: '',
      not_texto: '',
      not_fecha: '',
      not_fecha_vence: '',
      not_tipo: '',
      not_img: '',
      not_foto: '',
      not_video: '',
      not_meta: '',
      not_s: '',
      not_usuario: '',

      listas_similar: [],
      listas_destacado: [],
    };
  }

  componentWillMount () {
    window.H5_loading.show();
    this.verNoticia(this.props.match.params.id)
    this.listaDestacado()
    window.scrollTo(0, 0)
  }
  
  componentDidMount () {
    window.H5_loading.hide();
  }

  verNoticia(ver) {
    window.H5_loading.show();
    var params = new URLSearchParams();
    params.append('ver', ver);
    params.append('cual', 'noticias');

    Axios.post(`${window.ws}wsPublica.php`, params)
      .then(response => {
        if(response.data.elError===1){
          this.props.history.push('/');
          console.log(response.data.mensaje)
        }else{
          this.setState({
            not_id: response.data.not_id,
            not_titulo: response.data.not_titulo,
            not_texto: response.data.not_texto,
            not_fecha: response.data.not_fecha,
            not_fecha_vence: response.data.not_fecha_vence,
            not_tipo: response.data.not_tipo,
            not_img: response.data.not_img,
            not_foto: response.data.not_foto,
            not_video: response.data.not_video,
            not_meta: response.data.not_meta,
            not_s: response.data.not_s,
            not_usuario: response.data.not_usuario,

            listas_similar: response.data.lista_array,
          })
          document.title = response.data.not_titulo
        }
      })
      .catch(error => {
        console.log(error)
      })
      .then(function() {
          window.H5_loading.hide();
      })
  }

  listaDestacado() {
    window.H5_loading.show();
    var params = new URLSearchParams();
    //params.append('c_tipo', this.props.match.params.id);
    params.append('cual', 'noticias_destacado');

    Axios.post(`${window.ws}wsPublica.php`, params)
      .then(response => {      
        if(response.data.elError===1){
          this.setState({listas_destacado: []})
          console.log(response.data.mensaje)
        }else{
          this.setState({listas_destacado: response.data.lista_array})
        }
      })
      .catch(error => {
        console.log(error)
      })
      .then(function() {
          window.H5_loading.hide();
      })
  }

  noticiaShow(ver){
    this.verNoticia(ver);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    this.props.history.push('../noticias/'+ver);
  }

  render () {
    let arrayVideo
    if(this.state.not_video){
      arrayVideo = this.state.not_video.split('/');
    }
    
    //const { router, params, location, routes } = this.props
    //this.props.location.state recibir el state del Link
    let url = this.state.not_titulo.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '') //eslint-disable-line
    //link = link.replace(/ /g, '')
    url = url.substring(0, 500)
    url = url.replace(' ', '%20')
    let face = window.window.rutas+"noticias/"+url+"&"+this.state.not_id
    //face = "www.cambio21.com.mx"

    let twi = this.state.not_titulo.replace(/ /g, '+')

    let foto = ""
    if(this.state.not_foto){
      foto = `${window.ws}fotos/${this.state.not_id/182}.${this.state.not_foto}`
    }else{
      if(this.state.not_img){
        foto = `${window.rutas}imagenes/noticias/${this.state.not_img}`
      }else{
        foto = "../img/fondo.svg"
      }
    }

    let miFecha = new Date(`${this.state.not_fecha.split(" ")[0]}T00:00`)

    return (
      <div>
        <div className="bg_blanco">
          <div className="max_width padding_width">
            <StickyContainer className="flex_noticias">
              <div className="noticias_ver">
                
                <div className="noticias_foto" data-aos="fade-right" data-aos-duration="1000">
                  <img src={foto} title={this.state.not_titulo} alt={this.state.not_titulo}/>
                  <div className="noticias_tipo">{this.state.not_tipo}</div>
                </div>

                <div className="noticia_titulo">{this.state.not_titulo}</div>
                <div className="noticia_fecha">
                  {
                    localStorage._ROL==='ADMIN' ?
                      <div><Link to={{pathname:`../panel`, state: this.state.not_id}}><img src="../img/editar.png" alt="Editar" title="Editar" height="20px" className="grises puntero"/></Link></div>
                    :
                      null
                  }
                  {`${window.dia[miFecha.getDay()]} ${miFecha.getDate()} de ${window.meses[miFecha.getMonth()]} de ${miFecha.getFullYear()}`}
                </div>
                <div className="noticia_linea"></div>
                <br/>
                <div dangerouslySetInnerHTML={{ __html: this.state.not_texto }} className="noticia_texto"/>
                {
                  this.state.not_video ?
                    <div>
                      <div id="cl"><br/></div>
                      <iframe className="videos_noticias" src={`https://www.youtube.com/embed/${arrayVideo[3]}`} title={this.state.not_titulo} allowfullscreen></iframe>
                      <div id="cl"></div>
                    </div>
                  :
                    null
                }
                <br/>
                <div className="noticia_linea"></div>
                <div className="leyenda_compa" data-aos="fade-left" data-aos-duration="1000">
                  Compartir publicación
                  <div className="compartir start">
                    <a href={`https://www.facebook.com/sharer.php?u=${face}`} target="_new"><img src="../img/r_face-01.svg" title="Facebook" alt="Facebook"/></a>
                    <a href={`https://twitter.com/intent/tweet?text=${twi}&url=${face}`} target="_new"><img src="../img/r_twi-01.svg" title="Facebook" alt="Facebook"/></a>
                    <a href={`https://api.whatsapp.com/send?text=${twi}%20%0A%0A%20${face}`} target="_new"><img src="../img/r_whats-01.svg" title="Facebook" alt="Facebook"/></a>
                  </div>
                </div>
              </div>
              <div className="noticias_seguir">
                <Sticky>
                  {({
                    style,
                    isSticky,
                    wasSticky,
                    distanceFromTop,
                    distanceFromBottom,
                    calculatedHeight
                  }) => (
                    <header style={style} className="noticias_publicidad" data-aos="fade-left" data-aos-duration="1000">
                      {
                        this.state.listas_destacado.length>0 ?
                          <div>
                            <h50>Exclusivas</h50>
                            <div className="linea_exclusiva"></div>
                            {
                              this.state.listas_destacado.filter(p => p.not_id!==this.state.not_id).slice(0, 5).map((item, i) => {
                                let link = item.not_titulo.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '') //eslint-disable-line
                                //link = link.replace(/ /g, '')
                                link = link.substring(0, 500)
                                link = link+"&"+item.not_id

                                let foto = ""
                                if(item.not_foto){
                                  foto = `${window.ws}fotos/${item.not_id/182}.${item.not_foto}`
                                }else{
                                  if(item.not_img){
                                    foto = `${window.rutas}imagenes/noticias/${item.not_img}`
                                  }else{
                                    foto = "../img/fondo.svg"
                                  }
                                }

                                return(
                                  <div className="flex_exclusivas" onClick={this.noticiaShow.bind(this, link)} key={i}>
                                    <img src={foto} title={item.not_titulo} alt={item.not_titulo}/>
                                    <div>{item.not_titulo}</div>
                                  </div>
                                )
                              })
                            }
                          </div>
                        :
                          null
                      }
                    </header>
                  )}
                </Sticky>
              </div>
            </StickyContainer>

            {
              this.state.listas_destacado.length>0 ?
                <div className="noticias_publicidad quita_publicidad" data-aos="fade-right" data-aos-duration="500">
                  <h50>Exclusivas</h50>
                  <div className="linea_exclusiva"></div>
                  {
                    this.state.listas_destacado.filter(p => p.not_id!==this.state.not_id).slice(0, 5).map((item, i) => {
                      let link = item.not_titulo.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '') //eslint-disable-line
                      //link = link.replace(/ /g, '')
                      link = link.substring(0, 500)
                      link = link+"&"+item.not_id

                      let foto = ""
                      if(item.not_foto){
                        foto = `${window.ws}fotos/${item.not_id/182}.${item.not_foto}`
                      }else{
                        if(item.not_img){
                          foto = `${window.rutas}imagenes/noticias/${item.not_img}`
                        }else{
                          foto = "../img/fondo.svg"
                        }
                      }

                      return(
                        <div className="flex_exclusivas" onClick={this.noticiaShow.bind(this, link)} key={i}>
                          <img src={foto} title={item.not_titulo} alt={item.not_titulo}/>
                          <div>{item.not_titulo}</div>
                        </div>
                      )
                    })
                  }
                </div>
              :
                null
            }         

          </div>
        </div>
        
        {
          this.state.listas_similar.length>0 ? 
            <div className="bg_gris">
              <div className="max_width padding_width">
                <center><h14>Te puede gustar</h14></center>
                <div id="cl"><br/><br/></div>
                <div className="flex_similar">
                  {
                    this.state.listas_similar.slice(0, 4).map((item, i) => {
                      let link = item.not_titulo.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '') //eslint-disable-line
                      //link = link.replace(/ /g, '')
                      link = link.substring(0, 500)
                      link = link+"&"+item.not_id

                      let foto = ""
                      if(item.not_foto){
                        foto = `${window.ws}fotos/${item.not_id/182}.${item.not_foto}`
                      }else{
                        if(item.not_img){
                          foto = `${window.rutas}imagenes/noticias/${item.not_img}`
                        }else{
                          foto = "../img/fondo.svg"
                        }
                      }

                      let miFecha = new Date(`${item.not_fecha.split(" ")[0]}T00:00`)

                      return(
                        <similar key={i}>
                          <img src={foto} title={item.not_titulo} alt={item.not_titulo} onClick={this.noticiaShow.bind(this, link)}/>
                          <div onClick={this.noticiaShow.bind(this, link)} className="similar_titulo">{item.not_titulo}</div>
                          <div className="similar_fecha">{item.not_tipo} | {`${window.meses[miFecha.getMonth()]} ${miFecha.getDate()}, ${miFecha.getFullYear()}`}</div>
                        </similar>
                      )
                    })
                  }
                </div>
              </div>
            </div>
          :
            null
        }
      </div>
    )
  }
}
*/
export default Noticias;
